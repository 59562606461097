import React from 'react'
import { Table, TableHead, TableRow, TableCell, TableBody } from '@bosch/react-frok'
import { IFleetTableProps } from '../../../propsInterfaces/ITableProps'
import Co2FleetReportTableCell from '../cells/Co2FleetReportTableCell'
import './ReportTable.scss'

const Co2FleetReportTable: React.FunctionComponent<IFleetTableProps> = ({ reports }) => {
  return (
    <div className="fleet-reports-table">
      <Table>
        <TableHead>
          <TableRow>
            <TableCell header secondary>
              Fleet
            </TableCell>
            <TableCell header secondary>
              CO₂ Emissions (tons CO₂eq)
            </TableCell>
            <TableCell header secondary>
              Number of Refuel Events
            </TableCell>
            <TableCell header secondary>
              Refuel Amount (liters)
            </TableCell>
            <TableCell header secondary>
              CO₂ Savings vs. Fossil Fuels (tons CO₂eq)
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {reports.map((report) => (
            <TableRow key={report.fleetName}>
              <Co2FleetReportTableCell report={report} />
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  )
}

export default Co2FleetReportTable
