import React from 'react'
import { ChartData, ChartOptions } from 'chart.js'
import { Pie } from 'react-chartjs-2'
import { Notification } from '@bosch/react-frok'
import './FleetAreaAndPieChart.scss'
import 'chart.js/auto'
import 'chartjs-plugin-datalabels'
import { formatWithMinZeroFractionDigits } from '../../../services/formatNumber'
import { mockTotalLiters } from '../../../__mocks__/generators/FleetChartGenerator'

const FleetPieChart: React.FunctionComponent<{ vehicleCheckedSelection: string[] }> = ({ vehicleCheckedSelection }) => {
  const totalLiters = mockTotalLiters(vehicleCheckedSelection)
  const chartOptions: ChartOptions<'pie'> = {
    plugins: {
      datalabels: {
        display: true,
        font: {
          size: 12,
          weight: 'bold',
        },
        color: 'white',
        backgroundColor: 'rgba(0,0,0,0.3)',
        borderColor: 'white',
        borderRadius: 5,
        borderWidth: 2,
        anchor: 'end',
        align: 'center',
        formatter: (value: number, context: any) => {
          const index = context.dataset.data.indexOf(value)
          const label = context.chart.data.labels?.[index]
          return ` ${label}: ${value}%\n (${formatWithMinZeroFractionDigits((value / 100) * totalLiters)} L) `
        },
      },
      tooltip: {
        enabled: false,
      },
      legend: {
        display: true,
        position: 'bottom',
        align: 'start',
        labels: {
          usePointStyle: true,
          pointStyle: 'circle',
          boxHeight: 6,
          font: {
            size: 12,
          },
        },
      },
    },
    layout: {
      padding: 10,
    },
  }

  const chartData: ChartData<'pie'> = {
    labels: ['HVO', 'Fossil'],
    datasets: [
      {
        data: [20, 80],
        backgroundColor: ['#81FCA8', '#FFD36D'],
      },
    ],
  }

  return (
    <div className="fleet-pie-graph">
      <div className="fleet-vehicles-dashboard">
        <div className="title-section">
          <p className="title">Fossil vs. Low-Carbon Fuel</p>
        </div>
      </div>
      <Pie data={chartData} options={chartOptions} />
      <Notification icon="alert-info" className="chart-info">
        Fuel type comparison based on the total liters consumed in the selected fleet with analyzed vehicles; Low-Carbon Fuel data is based
        on historical data from the fuel supply chain.
      </Notification>
    </div>
  )
}

export default FleetPieChart
