import React from 'react'
import { Table, TableRow, TableCell, TableBody, Icon, TableHead } from '@bosch/react-frok'
import { ICo2ReductionsTableProps } from '../../../propsInterfaces/ITableProps'
import './Co2ReductionTable.scss'
import { formatWithMinZeroFractionDigits } from '../../../services/formatNumber'
import { getUnit } from '../../../services/reportsUtil'

const Co2ReductionTable: React.FunctionComponent<ICo2ReductionsTableProps> = ({ reductions, reportType, selectedLastXDays }) => {
  const unitDivision = reportType === 'Vehicle' ? 1000 : 1000000
  return (
    <div className="co2-reductions-table">
      <Table>
        <TableHead>
          <tr>
            <th>Day</th>
            <th>Name</th>
            <th>{getUnit(reportType) + ' Reduction'}</th>
            <th>{'Difference from ' + selectedLastXDays + ' day(s) avg'}</th>
          </tr>
        </TableHead>
        <TableBody>
          {reductions.map((reduction) => (
            <TableRow key={reduction.name + reduction.day}>
              <TableCell>{reduction.day}</TableCell>
              <TableCell>{reduction.name}</TableCell>
              <TableCell>{formatWithMinZeroFractionDigits(reduction.amount / unitDivision)}</TableCell>
              <TableCell>
                {reduction.difference && parseFloat(reduction.difference) <= 0 ? (
                  <div className="percentage-down">
                    <div className="down">{formatWithMinZeroFractionDigits(parseFloat(reduction.difference) / unitDivision)}</div>
                    {reduction.amount == 0 ? null : <Icon iconName="arrow-down-frame" className="percentage-icon-up" />}
                  </div>
                ) : (
                  <div className="percentage-up">
                    <div className="up">{formatWithMinZeroFractionDigits(parseFloat(reduction.difference) / unitDivision)}</div>
                    <Icon iconName="arrow-up-frame" className="percentage-icon-up" />
                  </div>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  )
}

export default Co2ReductionTable
