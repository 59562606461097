import React from 'react'
import './VehiclesForFleetTable.scss'
import { Table, TableHead, TableRow, TableCell, TableBody, Checkbox } from '@bosch/react-frok'
import { IVehicleForFleeTableProps } from '../../../propsInterfaces/ITableProps'

const VehiclesForFleetTable: React.FunctionComponent<IVehicleForFleeTableProps> = ({
  selectableIds,
  setCheckedSelection,
  checkedSelection,
}) => {
  const getNewSelection = (selected: string, prevSelected: string[]) => {
    let isChecked = undefined
    isChecked = isIdChecked(selected)

    if (prevSelected.includes(selected)) {
      if (isChecked) {
        return prevSelected.filter((prev) => prev !== selected)
      } else {
        return prevSelected
      }
    } else {
      if (!isChecked) {
        return [...prevSelected, selected]
      } else {
        return prevSelected
      }
    }
  }

  const handleCheck = (id: string) => {
    setCheckedSelection((prevSelected: string[]) => getNewSelection(id, prevSelected))
  }

  const handleCheckAll = () => {
    setCheckedSelection(selectableIds)
  }

  const handleUncheckAll = () => {
    setCheckedSelection([])
  }

  const isIdChecked = (selectable: string) => {
    return checkedSelection.includes(selectable)
  }

  return (
    <div className="vehicles-for-fleet-selection">
      <div className="select-all">
        <p className="check-all" onClick={handleCheckAll}>
          Check All
        </p>
        <p className="uncheck-all" onClick={handleUncheckAll}>
          Uncheck All
        </p>
      </div>
      <div className="selection-table">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell header secondary>
                Name
              </TableCell>
              <TableCell header secondary>
                Select
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {selectableIds.map((selectableId) => (
              <TableRow key={selectableId}>
                <TableCell>{selectableId}</TableCell>
                <TableCell>
                  <Checkbox
                    className="checkboxes"
                    id={'vehicle-for-fleet' + selectableId}
                    label=""
                    onChange={() => handleCheck(selectableId)}
                    checked={isIdChecked(selectableId)}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </div>
  )
}

export default VehiclesForFleetTable
