import { ReportDataType } from '../../models/ReportDataType'

const getSavingsFromSelectedGhg = (ghg: number, refuelAmount: number, emissions: number): number => {
  return ghg * refuelAmount - emissions
}

export const mockReportsBasedOnGhg = <T extends ReportDataType>(reportsData: T[], selectedGhg: number): T[] => {
  const reportsBasedOnGhg = reportsData.map((reportData) => ({
    ...reportData,
    co2savingsComparedToFossil: getSavingsFromSelectedGhg(selectedGhg, reportData.refuelAmount, reportData.co2emissions),
  }))
  return reportsBasedOnGhg
}
