import React from 'react'
import './SelectionTable.scss'
import { Table, TableHead, TableRow, TableCell, TableBody, Checkbox } from '@bosch/react-frok'
import { ISelectionTableProps } from '../../propsInterfaces/ITableProps'

const SelectionTable: React.FunctionComponent<ISelectionTableProps> = ({
  selectableNames,
  selectableIds,
  setCheckedSelection,
  checkedSelection,
  checkedSelectionNames,
  setCheckedSelectionNames,
  maxSelection,
  setMaxSelectionExceeded,
}) => {
  const getNewSelection = (selected: string, prevSelected: string[], type: 'name' | 'id') => {
    let isChecked = undefined
    if (type === 'name') {
      isChecked = isNameChecked(selected)
    } else {
      isChecked = isIdChecked(selected)
    }

    if (prevSelected.includes(selected)) {
      if (isChecked) {
        setMaxSelectionExceeded(false)
        return prevSelected.filter((prev) => prev !== selected)
      } else {
        return prevSelected
      }
    } else {
      if (!isChecked && checkedSelection.length < maxSelection) {
        setMaxSelectionExceeded(false)
        return [...prevSelected, selected]
      } else {
        setMaxSelectionExceeded(true)
        return prevSelected
      }
    }
  }

  const handleCheck = (id: string, name?: string) => {
    setCheckedSelection((prevSelected: string[]) => getNewSelection(id, prevSelected, 'id'))
    if (name)
      if (setCheckedSelectionNames) {
        setCheckedSelectionNames((prevSelected: string[]) => getNewSelection(name, prevSelected, 'name'))
      }
  }

  const isIdChecked = (selectable: string) => {
    return checkedSelection.includes(selectable)
  }

  const isNameChecked = (selectable: string) => {
    if (checkedSelectionNames) return checkedSelectionNames.includes(selectable)
  }

  return (
    <div className="selection-table">
      <Table>
        <TableHead>
          <TableRow>
            <TableCell header secondary>
              Name
            </TableCell>
            <TableCell header secondary>
              Select
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {selectableNames
            ? selectableNames.map((selectableName, index) => (
                <TableRow key={selectableName}>
                  <TableCell>{selectableName}</TableCell>
                  <TableCell>
                    <Checkbox
                      className="checkboxes"
                      id={selectableIds[index]}
                      label=""
                      onChange={() => handleCheck(selectableIds[index], selectableName)}
                      checked={isIdChecked(selectableIds[index])}
                    />
                  </TableCell>
                </TableRow>
              ))
            : selectableIds.map((selectableId) => (
                <TableRow key={selectableId}>
                  <TableCell>{selectableId}</TableCell>
                  <TableCell>
                    <Checkbox
                      className="checkboxes"
                      id={selectableId}
                      label=""
                      onChange={() => handleCheck(selectableId)}
                      checked={isIdChecked(selectableId)}
                    />
                  </TableCell>
                </TableRow>
              ))}
        </TableBody>
      </Table>
    </div>
  )
}

export default SelectionTable
