export const vehicleIds = [
  { id: '2240006439' },
  { id: '2240006440' },
  { id: '2240006433' },
  { id: '2240006437' },
  { id: '2240006380' },
  { id: '2240006382' },
  { id: '2240006383' },
  { id: '2240006389' },
  { id: '2240006386' },
  { id: '2240006396' },
  { id: '2240006390' },
  { id: '2240006428' },
  { id: '2240006385' },
]

export const fleetNamesAndIds = [{ id: '1', name: 'Example Logistics' }]

export const allVehicles = [
  '2240006439',
  '2240006440',
  '2240006433',
  '2240006437',
  '2240006380',
  '2240006382',
  '2240006383',
  '2240006389',
  '2240006386',
  '2240006396',
  '2240006390',
  '2240006428',
  '2240006385',
]
