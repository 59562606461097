import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import '@bosch/frontend.kit-npm/dist/frontend-kit.css'
import { MsalProvider } from '@azure/msal-react'
import { EventType, EventMessage, AuthenticationResult, PublicClientApplication } from '@azure/msal-browser'
import msalConfig from './config/msalConfig'
import { Amplify, ResourcesConfig } from 'aws-amplify'
import { AwsConfigAuth } from './config/awsConfig'

const pca = new PublicClientApplication(msalConfig)
Amplify.configure(AwsConfigAuth as ResourcesConfig)

pca.initialize().then(() => {
  const accounts = pca.getAllAccounts()
  if (accounts.length > 0) {
    pca.setActiveAccount(accounts[0])
  }

  pca.addEventCallback((event: EventMessage) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
      const payload = event.payload as AuthenticationResult
      const account = payload.account
      pca.setActiveAccount(account)
    }
  })

  const AppProvider = () => (
    <MsalProvider instance={pca}>
      <App />
    </MsalProvider>
  )

  const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
  root.render(
    <React.StrictMode>
      <AppProvider />
    </React.StrictMode>,
  )

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals()
})
