import env from 'react-dotenv'

const environment = env.ENVIRONMENT_TYPE || 'Local'

let API_SERVICE_URL: string
let API_KEY: string
let REDIRECT_URI: string
//AAD for internals
const AAD_TENANT_ID = '0ae51e19-07c8-4e4b-bb6d-648ee58410f4'
const AAD_CLIENT_ID = '31442b11-6c7d-4bff-a433-109b8770dd3f'
//AWS Cognito for externals
const AWS_AUTH_REGION = 'eu-central-1'
const AWS_AUTH_USER_ID = 'eu-central-1_cYJwmZFIs'
const AWS_AUTH_CLIENT_ID = '3a3f3kdk3p8fgmv2jbvvlvs63k'
const AWS_AUTH_DOMAIN = 'https://cpsdft.auth.eu-central-1.amazoncognito.com'

switch (environment) {
  case 'Development':
    API_KEY = process.env.API_KEY || env.API_KEY || 'api key undefined'
    API_SERVICE_URL = 'https://lila.development.digitalfueltwin-solutions.com'
    REDIRECT_URI = 'https://co2-fleet-dashboard.development.digitalfueltwin.bosch.tech'
    break
  case 'Local':
    API_KEY = process.env.REACT_APP_API_KEY || 'api key undefined'
    API_SERVICE_URL = 'https://lila.development.digitalfueltwin-solutions.com'
    REDIRECT_URI = 'http://localhost:3000'
    break
  default:
    API_KEY = process.env.API_KEY || 'api key undefined'
    API_SERVICE_URL = 'https://lila.development.digitalfueltwin-solutions.com'
    REDIRECT_URI = 'https://co2-fleet-dashboard.development.digitalfueltwin.bosch.tech'
    break
}

export const environmentConstants = {
  API_KEY,
  API_SERVICE_URL,
  REDIRECT_URI,
  AAD_TENANT_ID,
  AAD_CLIENT_ID,
  AWS_AUTH_REGION,
  AWS_AUTH_USER_ID,
  AWS_AUTH_CLIENT_ID,
  AWS_AUTH_DOMAIN,
}
