import { simulatedMultipliers } from '../data/MockAreaChartData'
import { vehicleTableData } from '../data/MockReport'

const getValueFromSelectedGhg = (ghg: number, refuelAmount: number): number => {
  return ghg * refuelAmount
}

export const mockFossilSimulatedByVehicles = (vehicleCheckedSelection: string[], selectedGhg: number): number[] => {
  const filteredVehicles = vehicleTableData.filter((vehicle) => vehicleCheckedSelection.includes(vehicle.vehicleName))
  const totalCO2Emissions = filteredVehicles.reduce(
    (sum, vehicle) => sum + getValueFromSelectedGhg(selectedGhg, vehicle.refuelAmount) / 1000000,
    0,
  )

  const mockArray: number[] = [0]
  for (let i = 1; i <= 12; i++) {
    mockArray.push((totalCO2Emissions / 12) * simulatedMultipliers[i])
  }

  return mockArray
}

//mock a smaller value than the simulated
export const mockRealFuelCo2ByVehicles = (vehicleCheckedSelection: string[], selectedGhg: number): number[] => {
  const mockSimulated = mockFossilSimulatedByVehicles(vehicleCheckedSelection, selectedGhg)
  return mockSimulated.map((value) => (value - 0.02 > 0 ? value - 0.02 : 0))
}

//mock an intermediate value between the simulated and real fuel
export const mockLimitedFuelByVehicles = (vehicleCheckedSelection: string[], selectedGhg: number): number[] => {
  const mockSimulated = mockFossilSimulatedByVehicles(vehicleCheckedSelection, selectedGhg)
  return mockSimulated.map((value) => (value - 0.01 > 0 ? value - 0.01 : 0))
}

//mock total liters
export const mockTotalLiters = (vehicleCheckedSelection: string[]): number => {
  const filteredVehicles = vehicleTableData.filter((vehicle) => vehicleCheckedSelection.includes(vehicle.vehicleName))
  const totalLiters = filteredVehicles.reduce((sum, vehicle) => sum + vehicle.refuelAmount, 0)

  return totalLiters
}
