import React, { useEffect, useState } from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import './MainSections.scss'
import DatePicker from 'react-datepicker'
import VehicleReportData from '../../models/VehicleReportData'
import Co2VehicleReportTable from '../../atoms/tables/reports/Co2VehicleReportTable'
import { vehicleTableData } from '../../__mocks__/data/MockReport'
import { Button, Chip, PageIndicator } from '@bosch/react-frok'
import EmissionsAndConsumtion from '../../molecules/EmissionsAndConsumption/EmissionsAndConsumtion'
import SelectionDialog from '../../molecules/SelectionDialog/SelectionDialog'
import { vehicleIds } from '../../__mocks__/data/MockSlectables'
import Co2Reduction from '../../molecules/Co2Reduction/Co2Reduction'
import MainReportsGraph from '../../atoms/graphs/MainReportsGraph'
import { IReportAnalysisProps } from '../../propsInterfaces/IReportProps'
import { getEmissionsvsMileageById } from '../../__mocks__/generators/VehicleChartGenerator'
import { mockReportsBasedOnGhg } from '../../__mocks__/generators/ReportGenerator'

const VehiclesReport: React.FunctionComponent<IReportAnalysisProps> = ({ selectedGhg }) => {
  const currentDate = new Date()
  const firstDate = new Date(2023, 11, 22)
  const [vehicleStartDate, setVehicleStartDate] = useState<Date | null>(firstDate)
  const [vehicleEndDate, setVehicleEndDate] = useState<Date | null>(currentDate)
  const [dialogOpen, setDialogOpen] = useState<boolean>(false)
  const [checkedSelection, setCheckedSelection] = useState<string[]>(['2240006439', '2240006440', '2240006433', '2240006437'])
  const [selectedFirstVehicleId, setSelectedFirstVehicleId] = useState<string>('2240006439')
  const [selectedSecondVehicleId, setSelectedSecondVehicleId] = useState<string>('2240006439')
  const [vehicleReports, setVehicleReports] = useState<VehicleReportData[]>(vehicleTableData)
  const numberOfPages = 1

  const handleVehicleDateChange = (range: [Date | null, Date | null]) => {
    const [startDate, endDate] = range
    setVehicleStartDate(startDate)
    setVehicleEndDate(endDate)
  }

  const handleDialogClick = () => {
    setDialogOpen(!dialogOpen)
  }

  const handleClose = (selected: string) => {
    const newCheckedSelection = checkedSelection.filter((val) => val !== selected)
    setCheckedSelection(newCheckedSelection)
  }

  const handleFirstVehicleIdDropdownChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedFirstVehicleId(event.target.value)
  }

  const handleSecondVehicleIdDropdownChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedSecondVehicleId(event.target.value)
  }

  const filteredVehicleReports =
    checkedSelection.length !== 0 ? vehicleReports.filter((vehicle) => checkedSelection.includes(vehicle.vehicleName)) : []

  useEffect(() => {
    setVehicleReports(mockReportsBasedOnGhg(vehicleTableData, selectedGhg) as VehicleReportData[])
  }, [selectedGhg])

  return (
    <div className="main-section">
      <p className="reports-paragraph">Vehicle Report </p>
      <Button
        className="select-data-button"
        label="Vehicle"
        icon={{
          iconName: 'add',
          title: 'Select vehicles',
        }}
        onClick={handleDialogClick}
      />
      {checkedSelection.map((selected) => (
        <Chip
          buttonClose
          key={'fleet' + selected}
          chipLabelId={'vehicle-selected-chip-' + selected}
          onClick={() => {
            handleClose(selected)
          }}
          label={selected}
        />
      ))}
      {filteredVehicleReports.length !== 0 && (
        <div className="analyzed-data">
          <div className="main-graphs">
            <EmissionsAndConsumtion reportType="Vehicle" vehicleSelectedData={checkedSelection} />
            <Co2Reduction reportType="Vehicle" selectedData={checkedSelection} selectedGhg={selectedGhg} />
          </div>
          {checkedSelection.length > 1 && <p className="vehicle-emissions-title">Vehicle Comparison Overview </p>}
          {checkedSelection.length === 1 && <p className="single-vehicle-emissions-title">Select more vehicles to compare them </p>}
          <div className="vehicle-emissions-graphs">
            <MainReportsGraph
              datasets={getEmissionsvsMileageById(selectedFirstVehicleId)}
              graphType={'Emissions vs. Mileage'}
              reportType="Vehicle"
              vehicleDropdown={checkedSelection ? checkedSelection : []}
              handleVehicleIdDropdownChange={handleFirstVehicleIdDropdownChange}
            />
            {checkedSelection.length > 1 && (
              <MainReportsGraph
                datasets={getEmissionsvsMileageById(selectedSecondVehicleId)}
                graphType={'Emissions vs. Mileage'}
                reportType="Vehicle"
                vehicleDropdown={checkedSelection ? checkedSelection : []}
                handleVehicleIdDropdownChange={handleSecondVehicleIdDropdownChange}
              />
            )}
          </div>
        </div>
      )}
      {dialogOpen && (
        <SelectionDialog
          selectableIds={vehicleIds.map((vehicle) => vehicle.id)}
          setDialogOpen={setDialogOpen}
          setCheckedSelection={setCheckedSelection}
          checkedSelection={checkedSelection}
          setVehicleReports={setVehicleReports}
          startDate={vehicleStartDate ? vehicleStartDate : new Date()}
          endDate={vehicleEndDate ? vehicleEndDate : new Date()}
        />
      )}
      <div className="report-date">
        <p className="report-date-text">Table Report Dates: </p>
        <DatePicker
          className="date-picker"
          selected={vehicleStartDate}
          onChange={handleVehicleDateChange}
          startDate={vehicleStartDate}
          endDate={vehicleEndDate}
          selectsRange
          disabled={true}
          dateFormat={'dd/MM/yyyy'}
        />
      </div>
      <div className="vehicle-results">
        {filteredVehicleReports.length !== 0 && <Co2VehicleReportTable reports={filteredVehicleReports} />}
        {filteredVehicleReports.length === 0 && vehicleReports.length !== 0 && <Co2VehicleReportTable reports={vehicleReports} />}
        {vehicleReports.length === 0 && <p className="no-reports-paragraph">No Vehicle Reports yet...</p>}
        <div id="vehicle-page-indicator">
          <PageIndicator numbered defaultSelected={1} pages={numberOfPages} />
        </div>
      </div>
    </div>
  )
}

export default VehiclesReport
