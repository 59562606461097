import React from 'react'
import { Button } from '@bosch/react-frok'
import { GraphType, IMainGraphSelectionProps } from '../../propsInterfaces/IGraphsProps'
import './GraphsSelection.scss'

const GraphsSelection: React.FunctionComponent<IMainGraphSelectionProps> = ({ selectedGraph, setSelectedGraph }) => {
  const handleSlectionClick = (buttonName: GraphType) => {
    setSelectedGraph(buttonName)
  }

  return (
    <div className="graphs-selection">
      <Button
        label="CO₂ Emissions"
        className={selectedGraph === 'CO₂ Emissions' ? 'co2-selected' : 'co2'}
        onClick={() => {
          handleSlectionClick('CO₂ Emissions')
        }}
      />
      <Button
        label="Refuel Amount"
        className={selectedGraph === 'Refuel Amount' ? 'fuel-selected' : 'fuel'}
        onClick={() => {
          handleSlectionClick('Refuel Amount')
        }}
      />
    </div>
  )
}

export default GraphsSelection
