import Co2Reductions from '../../models/Co2Reductions'
import { InputData } from '../../models/GraphData'
import { filterLastXDays } from '../../services/graphUtil'
import {
  co2AllReductionsVehicleDatasets,
  co2ReductionDataCustomer,
  co2ReductionDataCustomerGasolineGLE,
  co2ReductionDataCustomerGasolineISO,
  co2ReductionDataFleet,
  co2ReductionsCustomerDatasets,
  co2ReductionsCustomerDatasetsGasolineGLE,
  co2ReductionsCustomerDatasetsGasolineISO,
} from '../data/MockGraphs'

const getSum = (input: InputData[], selectedLastXDays: number): number => {
  let sum = 0
  input.forEach((item) => {
    const { data } = item
    const filtered = filterLastXDays(data, selectedLastXDays)
    filtered.forEach((entry) => {
      const { y: currentYValue } = entry
      sum += currentYValue
    })
  })
  return sum
}

const processData = (input: InputData[], selectedLastXDays: number): Co2Reductions[] => {
  const finalResult: Co2Reductions[] = []

  input.forEach((item) => {
    const { label, data } = item
    const filtered = filterLastXDays(data, selectedLastXDays)

    filtered.forEach((entry) => {
      const { x: day, y: currentYValue } = entry
      const amount = Math.round(currentYValue)
      const amountChanged = currentYValue - getSum(input, selectedLastXDays) / selectedLastXDays

      finalResult.push({
        day,
        amount: Math.round(amount),
        difference: Math.floor(amountChanged) + '',
        name: label,
      })
    })
  })

  return finalResult
}

export const vehicleCo2ReductionsBasic = processData(co2AllReductionsVehicleDatasets, 7)
export const fleetCo2ReductionsBasic = processData(co2ReductionDataFleet, 7)
export const customerCo2ReductionsBasic = processData(co2ReductionDataCustomer, 7)

export const getCo2Reductions = (datasets: InputData[], selectedLastXDays: number) => {
  const nonZero = datasets.map((dataset) => ({ ...dataset, data: dataset.data.filter((d) => d.y !== 0) }))
  return processData(nonZero, selectedLastXDays)
}

export const getCustomerCo2Reductions = (selectedLastXDays: number, ghg: number): Co2Reductions[] => {
  if (ghg === 90.1) return processData(co2ReductionDataCustomerGasolineISO, selectedLastXDays)
  else if (ghg === 99.1) return processData(co2ReductionDataCustomerGasolineGLE, selectedLastXDays)
  else return processData(co2ReductionDataCustomer, selectedLastXDays)
}

export const getCustomerCo2ReductionsGraph = (ghg: number) => {
  if (ghg === 90.1) return co2ReductionsCustomerDatasetsGasolineISO
  else if (ghg === 99.1) return co2ReductionsCustomerDatasetsGasolineGLE
  else return co2ReductionsCustomerDatasets
}
