import React, { useState } from 'react'
import { IAuthProps } from '../../propsInterfaces/IAuthProps'
import { Box, Button } from '@bosch/react-frok'
import { loginRequest } from '../../config/msalConfig'
import ExternalForm from '../../molecules/CognitoForm/ExternalForm'
import './LoginPage.scss'

const LoginPage: React.FunctionComponent<IAuthProps> = ({ authInstance }) => {
  const [isExternalSelected, setExternalSelected] = useState<boolean>(false)

  const handleLogin = () => {
    authInstance.loginRedirect(loginRequest)
  }
  const handleExternalSelection = () => {
    setExternalSelected(!isExternalSelected)
  }

  return (
    <div className="login-page">
      <Box className="auth-box" open>
        <div className="auth-box-content">
          <div className="auth-box-logo">
            <img
              className="auth-box-logo-image"
              src="https://cdn-assets-eu.frontify.com/s3/frontify-enterprise-files-eu/eyJwYXRoIjoiYm9zY2hcL2ZpbGVcL3lmTXlYd1ZFQnVYanRZTTdhTEp4LnBuZyJ9:bosch:kVU4tK9bs1CIt8zF0HFimB-Gph48m7OR7q4qrvja5Fo?width={width}"
              alt="Logo"
            />
          </div>

          {!isExternalSelected && (
            <div className="internal-login">
              <p className="login-paragraph">Login for employees</p>
              <div className="auth-login-button">
                <Button label="Bosch Login" onClick={handleLogin} />{' '}
              </div>
              <p className="auth-note">Bosch Login only works in the enterprise network.</p>
              <p className="auth-login-externals" onClick={handleExternalSelection}>
                LOGIN FOR EXTERNALS
              </p>
              <a href="https://www.bosch.com/static/frontify/corporate-information.html" className="auth-info" rel="noopener noreferrer">
                Corporate Information
              </a>
            </div>
          )}

          {isExternalSelected && (
            <div className="external-login">
              <ExternalForm />
              <p className="auth-note">Logging in ensures a personalized experience and access to all our features.</p>
              <p className="auth-login-internals" onClick={handleExternalSelection}>
                LOGIN FOR EMPLOYEES
              </p>
              <a href="https://www.bosch.com/static/frontify/corporate-information.html" className="auth-info" rel="noopener noreferrer">
                Corporate Information
              </a>
            </div>
          )}
        </div>
      </Box>
    </div>
  )
}

export default LoginPage
