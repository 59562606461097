import React, { useEffect, useState } from 'react'
import './App.css'
import './config/chartConfig'
import LoginPage from './views/Login/LoginPage'
import Homepage from './views/HomePage/Homepage'
import { useIsAuthenticated, useMsal } from '@azure/msal-react'
import { getCurrentUser } from 'aws-amplify/auth'

function App() {
  const { instance } = useMsal()
  const [isCognitoAuthenticated, setIsCognitoAuthenticated] = useState(false)
  const [authMethod, setAuthMethod] = useState<'cognito' | 'msal' | null>(null)
  const isMsalAuthenticated = useIsAuthenticated()

  const checkCognitoAuth = async () => {
    try {
      await getCurrentUser()
      setIsCognitoAuthenticated(true)
      setAuthMethod('cognito')
    } catch (error) {
      return null
    }
  }

  useEffect(() => {
    if (isMsalAuthenticated) {
      setAuthMethod('msal')
    } else {
      checkCognitoAuth()
    }
  }, [isMsalAuthenticated])

  if (isCognitoAuthenticated || isMsalAuthenticated) {
    return <Homepage authInstance={instance} authMethod={authMethod} />
  } else {
    return <LoginPage authInstance={instance} />
  }
}

export default App
