import React, { useState } from 'react'
import { IMainGraphProps } from '../../propsInterfaces/IGraphsProps'
import { ChartData, ChartOptions } from 'chart.js'
import { Bar, Line } from 'react-chartjs-2'
import './ReportsGraph.scss'
import 'chart.js/auto'
import { Dropdown } from '@bosch/react-frok'
import { extractMonthString, extractMonthValue, filterLastXMonths, getDatasetTotals } from '../../services/graphUtil'
import { formatWithMinZeroFractionDigits, getUnitDivision } from '../../services/formatNumber'
import { getUnit } from '../../services/reportsUtil'

const MainReportsGraph: React.FunctionComponent<IMainGraphProps> = ({
  datasets,
  graphType,
  vehicleDropdown,
  reportType,
  handleVehicleIdDropdownChange,
}) => {
  const dropDownOptionsMileage = vehicleDropdown.map((vehicle: string) => ({ name: vehicle }))
  const lastMonths = extractMonthString()
  const colors = ['#9E2896', '#18837E', '#007BC0', '#5FB583']
  const vehicleColors = ['#9DC9FF', '#86D7A2']
  const isSmallScreen = window.innerWidth < 500
  const referenceMonth = 'May 2024'
  const [selectedLastXMonths, setSelectedLastXMonths] = useState<number>(13)
  const unitDivision = getUnitDivision(reportType, graphType)
  const titleStyle = graphType === 'Emissions vs. Mileage' ? 'graph-title-small' : 'graph-title'

  const dropDownOptions = lastMonths.map((month) => ({
    name: month,
    value: extractMonthValue(month),
  }))

  const filteredMonthsDatasets = datasets.map((dataset) => ({
    ...dataset,
    data: filterLastXMonths(dataset.data, referenceMonth, selectedLastXMonths, graphType, dataset.label),
  }))

  const totals = getDatasetTotals(filteredMonthsDatasets)

  const handleDropdownChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = parseInt(event.target.value, 10)
    setSelectedLastXMonths(selectedValue)
  }

  const chartOptions: ChartOptions<'bar'> = {
    responsive: true,
    maintainAspectRatio: true,
    aspectRatio: isSmallScreen ? 1.4 : 2,
    scales: {
      y: {
        title: {
          display: true,
          text: getUnit(reportType, graphType),
        },
        ticks: {
          callback: function (value: number | string) {
            if (typeof value === 'number') return formatWithMinZeroFractionDigits(value)
          },
        },
      },
    },
    plugins: {
      datalabels: {
        display: false,
      },
      tooltip: {
        backgroundColor: '#FFFFFF',
        bodyColor: '#000000',
        titleColor: '#000000',
        borderColor: '#C1C7CC',
        borderWidth: 1,
        callbacks: {
          label: function (tooltip) {
            const value = tooltip.raw as number
            const valueDisplayed = formatWithMinZeroFractionDigits(value)
            const name = tooltip.dataset.label
            const unit = graphType + ' (' + getUnit(reportType, graphType) + ')'
            return name + ': ' + valueDisplayed + ' ' + unit
          },
        },
      },
      legend: {
        display: true,
        position: 'bottom',
        align: 'start',
        labels: {
          usePointStyle: true,
          pointStyle: 'circle',
          boxHeight: 6,
          font: {
            size: 12,
          },
        },
      },
    },
    elements: {
      line: {
        borderWidth: 2,
      },
      point: {
        radius: 2.5,
        hoverRadius: 6,
      },
    },
    layout: {
      padding: 20,
    },
  }

  const chartOptionsVehicle: ChartOptions<'line'> = {
    responsive: true,
    maintainAspectRatio: true,
    aspectRatio: isSmallScreen ? 1.4 : 2,
    scales: {
      x: {
        title: {
          display: true,
          text: 'Mileage',
        },
      },
      y: {
        title: {
          display: true,
          text: 'kgCO₂eq/km',
        },
      },
    },
    plugins: {
      datalabels: {
        display: false,
      },
      tooltip: {
        backgroundColor: '#FFFFFF',
        bodyColor: '#000000',
        titleColor: '#000000',
        borderColor: '#C1C7CC',
        borderWidth: 1,
      },
      legend: {
        display: true,
        position: 'bottom',
        align: 'start',
        labels: {
          usePointStyle: true,
          pointStyle: 'circle',
          boxHeight: 6,
          font: {
            size: 12,
          },
        },
      },
    },
    elements: {
      line: {
        borderWidth: 2,
      },
      point: {
        radius: 2,
        hoverRadius: 5,
      },
    },
    layout: {
      padding: 20,
    },
  }

  const chartData: ChartData<'bar'> = {
    labels: filteredMonthsDatasets[0]?.data.map((d) => d.x) || [],
    datasets: filteredMonthsDatasets.map((dataset, index) => ({
      label: dataset.label,
      data: dataset.data.map((d) => (d.y !== 0 ? Math.round(d.y) / unitDivision : null)),
      fill: false,
      borderColor: colors[index % colors.length],
      backgroundColor: colors[index % colors.length],
      tension: 0.1,
      spanGaps: true,
      maxBarThickness: 30,
    })),
  }

  const chartDataVehicle: ChartData<'line'> = {
    labels: datasets[0]?.data.map((d) => d.x) || [],
    datasets: datasets.map((dataset, index) => ({
      label: dataset.label,
      data: dataset.data.map((d) => (d.y !== 0 ? Math.round(d.y) : null)),
      fill: false,
      borderColor: vehicleColors[index % colors.length],
      backgroundColor: vehicleColors[index % colors.length],
      tension: 0.1,
      spanGaps: true,
    })),
  }

  return (
    <div className={graphType !== 'Emissions vs. Mileage' ? 'main-reports-graph' : 'vehicle-mileage-graph'}>
      <div className="dashboard">
        <div className="title-section">
          <p className={titleStyle}>{graphType + ' (' + getUnit(reportType, graphType) + ')'}</p>
          <div className="totals">
            {graphType !== 'Emissions vs. Mileage' &&
              totals.map((total, index) => (
                <div key={index} className={'value-' + index}>
                  <div className="total">
                    <div className="tooltip">{'Total of ' + datasets[index].label + ' over the last 13 month'}</div>
                    {formatWithMinZeroFractionDigits(total / unitDivision)}
                  </div>
                </div>
              ))}
          </div>
        </div>
        {graphType === 'Emissions vs. Mileage' && (
          <Dropdown id="main-graph-vehicle-selection" options={dropDownOptionsMileage} onChange={handleVehicleIdDropdownChange} />
        )}
        {graphType !== 'Emissions vs. Mileage' && (
          <Dropdown id="main-graph-date-selection" options={dropDownOptions} onChange={handleDropdownChange} />
        )}
      </div>
      {graphType === 'Emissions vs. Mileage' && <Line data={chartDataVehicle} options={chartOptionsVehicle} />}
      {graphType !== 'Emissions vs. Mileage' && <Bar data={chartData} options={chartOptions} />}
    </div>
  )
}

export default MainReportsGraph
