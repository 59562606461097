export const mockMonths = [
  '01/01/2024',
  '31/01/2024',
  '29/02/2024',
  '31/03/2024',
  '30/04/2024',
  '31/05/2024',
  '30/06/2024',
  '31/07/2024',
  '31/08/2024',
  '30/09/2024',
  '31/10/2024',
  '30/11/2024',
  '31/12/2024',
]

export const mockFossilSimulatedData = [0, 0.001, 0.0025, 0.0032, 0.0044, 0.0049, 0.0057, 0.0072, 0.0078, 0.0087, 0.0095, 0.011, 0.012]
export const mockRealFuelCo2Data = [0, 0.0005, 0.0009, 0.0016, 0.002, 0.00274, 0.00349, 0.0046, 0.0052, 0.0057, 0.0069, 0.0078, 0.0096]
export const mockLimitedFuelData = [0, 0.00072, 0.0015, 0.0022, 0.003, 0.004, 0.0048, 0.0058, 0.0065, 0.0072, 0.0085, 0.009, 0.011]

export const simulatedMultipliers = [1, 1.3, 2.32, 2.61, 3.99, 4.56, 6.12, 7, 7.98, 8.65, 10.03, 10.98, 12]
