import React from 'react'
import { TableCell } from '@bosch/react-frok'
import { IFleetTableCellProps } from '../../../propsInterfaces/ITableProps'
import { formatWithMinZeroFractionDigits } from '../../../services/formatNumber'

const Co2FleetReportTableCell: React.FunctionComponent<IFleetTableCellProps> = ({ report }) => {
  return (
    <>
      <TableCell>{report.fleetName}</TableCell>
      <TableCell>{formatWithMinZeroFractionDigits(report.co2emissions / 1000000)}</TableCell>
      <TableCell>{report.nrOfRefuelEvents}</TableCell>
      <TableCell>{Math.round(report.refuelAmount)}</TableCell>
      <TableCell>{formatWithMinZeroFractionDigits(report.co2savingsComparedToFossil / 1000000)}</TableCell>
    </>
  )
}

export default Co2FleetReportTableCell
