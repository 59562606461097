export const getUnit = (reportType: string, graphType?: string) => {
  if (graphType && graphType === 'Refuel Amount') {
    return 'liters'
  }
  if (graphType && graphType === 'Emissions vs. Mileage') {
    return 'kg CO₂eq/km'
  }
  if (reportType === 'Vehicle') return 'kg CO₂eq'
  else return 'tons CO₂eq'
}
