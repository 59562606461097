import React, { useRef, useState } from 'react'
import { Button, Form, TextField, Notification } from '@bosch/react-frok'
import { confirmSignUp, signIn, signUp } from 'aws-amplify/auth'
import './ExternalForm.scss'

const ExternalForm = () => {
  const [isSignUpSelected, setSignUpSelected] = useState<boolean>(false)
  const [enteredUsername, setEnteredUsername] = useState<string>('')
  const [requestSend, setRequestSend] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>('')
  const signInFormRef = useRef<HTMLFormElement>(null)
  const signUpFormRef = useRef<HTMLFormElement>(null)

  const handleCognitoLogin = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const target = event.target as typeof event.target & {
      elements: { signinUsername: HTMLInputElement; signinPassword: HTMLInputElement }
    }
    const username = target.elements.signinUsername.value
    const password = target.elements.signinPassword.value
    try {
      const user = await signIn({ username, password })
      setErrorMessage('')
      window.location.reload()
      return user
    } catch (error: unknown) {
      if (error instanceof Error) {
        if (error.name === 'NotAuthorizedException' && error.message === 'Incorrect username or password.') {
          setErrorMessage(error.message)
        }
      }
      return null
    }
  }

  const handleCognitoSignUp = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const target = event.target as typeof event.target & {
      elements: { username: HTMLInputElement; password: HTMLInputElement; email: HTMLInputElement }
    }
    const username = target.elements.username.value
    const password = target.elements.password.value
    const email = target.elements.email.value
    setEnteredUsername(username)
    try {
      const { isSignUpComplete, userId, nextStep } = await signUp({
        username: username,
        password: password,
        options: {
          userAttributes: {
            email: email,
          },
        },
      })
      setErrorMessage('')
      setRequestSend(true)
      return { isSignUpComplete, userId, nextStep }
    } catch (error) {
      if (error instanceof Error) {
        setErrorMessage(error.message)
      }
    }
  }

  const handleCognitoConfirm = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const target = event.target as typeof event.target & {
      elements: { code: HTMLInputElement }
    }
    const code = target.elements.code.value
    try {
      const { isSignUpComplete, nextStep } = await confirmSignUp({
        username: enteredUsername,
        confirmationCode: code,
      })
      setErrorMessage('')
      setRequestSend(false)
      setSignUpSelected(false)
      return { isSignUpComplete, nextStep }
    } catch (error) {
      if (error instanceof Error) {
        setErrorMessage(error.message)
      }
    }
  }

  const handleSignUpSelection = () => {
    setSignUpSelected(!isSignUpSelected)
    if (!isSignUpSelected) {
      setRequestSend(false)
      setErrorMessage('')
      if (signInFormRef.current) {
        signInFormRef.current.reset()
      }
    } else {
      if (signUpFormRef.current) {
        signUpFormRef.current.reset()
      }
    }
  }

  if (!isSignUpSelected) {
    return (
      <div className="external-content">
        <p className="external-paragraph">Login for externals</p>
        <Form ref={signInFormRef} description={'Login'} className="external-form" onSubmit={handleCognitoLogin}>
          <TextField id="signinUsername" label="Username" className="form-field" required />
          <TextField id="signinPassword" label="Password" type="password" className="form-field" required />
          {errorMessage && (
            <Notification type="error" className="external-error">
              {errorMessage}
            </Notification>
          )}
          <div className="submit-button">
            <Button label="External Login" type="submit" />
          </div>
        </Form>
        <div className="change-option-button">
          <Button label="Sign Up" onClick={handleSignUpSelection} />
        </div>
      </div>
    )
  } else {
    return (
      <div className="external-content">
        <p className="external-paragraph">Sign up for externals</p>
        {!requestSend && (
          <Form ref={signUpFormRef} description={'Sign Up'} className="external-form" onSubmit={handleCognitoSignUp}>
            <TextField id="email" label="Email" className="form-field" required />
            <TextField id="username" label="Username" className="form-field" required />
            <TextField id="password" label="Password" type="password" className="form-field" required />
            {errorMessage && (
              <Notification type="error" className="external-error">
                {errorMessage}
              </Notification>
            )}
            <div className="submit-button">
              <Button label="Create Account" type="submit" />
            </div>
          </Form>
        )}

        {requestSend && (
          <Form description={'Confirm Email'} className="confirm-email-form" onSubmit={handleCognitoConfirm}>
            <TextField id="code" label="Confirmation Code" required />
            {errorMessage && (
              <Notification type="error" className="external-error">
                {errorMessage}
              </Notification>
            )}
            <div className="confirm-button">
              <Button label="Confirm Email" type="submit" />
            </div>
          </Form>
        )}

        <div className="change-option-button">
          <Button label="Sign In" onClick={handleSignUpSelection} />
        </div>
      </div>
    )
  }
}

export default ExternalForm
