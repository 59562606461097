import React from 'react'
import { TableCell } from '@bosch/react-frok'
import { IVehicleTableCellProps } from '../../../propsInterfaces/ITableProps'
import { formatWithMax2FractionDigits } from '../../../services/formatNumber'

const Co2VehicleReportTableCell: React.FunctionComponent<IVehicleTableCellProps> = ({ report }) => {
  return (
    <>
      <TableCell>{report.vehicleName}</TableCell>
      <TableCell>{formatWithMax2FractionDigits(report.co2emissions / 1000)}</TableCell>
      <TableCell>{report.nrOfRefuelEvents}</TableCell>
      <TableCell>{Math.round(report.refuelAmount)}</TableCell>
      <TableCell>{formatWithMax2FractionDigits(report.co2savingsComparedToFossil / 1000)}</TableCell>
    </>
  )
}

export default Co2VehicleReportTableCell
