import React, { useState } from 'react'
import './Co2Reduction.scss'
import { co2AllReductionsVehicleDatasets } from '../../__mocks__/data/MockGraphs'
import Co2ReductionGraph from '../../atoms/graphs/Co2ReductionGraph'
import { Dropdown, Icon } from '@bosch/react-frok'
import Co2ReductionTable from '../../atoms/tables/co2reduction/Co2ReductionTable'
import { formatWithMinZeroFractionDigits } from '../../services/formatNumber'
import { extractDayString } from '../../services/graphUtil'
import { getCo2Reductions, getCustomerCo2Reductions, getCustomerCo2ReductionsGraph } from '../../__mocks__/generators/Co2ReductionGenerator'

type ReportType = 'Customer' | 'Fleet' | 'Vehicle'

interface ICo2ReductionProps {
  reportType: ReportType
  selectedData?: string[]
  co2ReductionTarget?: number
  percentage?: string
  selectedGhg: number
}

// const percentageOptions = [
//   { value: 99.1, name: `GLE3.0 Gasoline - 99.1 ghg` },
//   { value: 96.5, name: `GLE3.0 Diesel - 96.5 ghg` },
//   { value: 94, name: `REDII (Basic) - 94 ghg` },
//   { value: 93.3, name: `GLE3.0 B7(gerechnet) - 93.3 ghg` },
//   { value: 90.1, name: `ISO 14083:2023 Gasoline - 90.1 ghg` },
//   { value: 87.4, name: `ISO 14083:2023 Diesel - 87.4 ghg` },
//   { value: 83.6, name: `ISO 14083:2023 B7(gerechnet) - 83.6 ghg` },
// ]

const Co2Reduction: React.FunctionComponent<ICo2ReductionProps> = ({
  reportType,
  selectedData,
  co2ReductionTarget,
  percentage,
  selectedGhg,
}) => {
  const [customerSelectedLastXDays, setCustomerSelectedLastXDays] = useState<number>(7)
  const [fleetSelectedLastXDays, setFleetSelectedLastXDays] = useState<number>(7)
  const [vehicleSelectedLastXDays, setVehicleSelectedLastXDays] = useState<number>(7)
  const dropDownOptions = extractDayString()

  const handleCustomerDaysDropdownChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = parseInt(event.target.value, 10)
    setCustomerSelectedLastXDays(selectedValue)
  }

  const handleFleetDaysDropdownChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = parseInt(event.target.value, 10)
    setFleetSelectedLastXDays(selectedValue)
  }

  const handleVehicleDaysDropdownChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = parseInt(event.target.value, 10)
    setVehicleSelectedLastXDays(selectedValue)
  }

  // useEffect(() => {
  //   // do stuff
  //   console.log(selectedGhg)
  // }, [selectedGhg])

  const getSelectedDataVehicles = () => {
    if (selectedData) {
      const result = co2AllReductionsVehicleDatasets.filter((vehicle) =>
        selectedData.some((substring) => vehicle.label.toLowerCase().includes(substring.toLowerCase())),
      )
      return result
    }
    return co2AllReductionsVehicleDatasets
  }

  const getSelectedDataFleets = () => {
    const resultByGhg = getCustomerCo2ReductionsGraph(selectedGhg)
    if (selectedData) {
      const result = resultByGhg.filter((fleet) =>
        selectedData.some((substring) => fleet.label.toLowerCase().includes(substring.toLowerCase())),
      )
      return result
    }
    return resultByGhg
  }

  return (
    <div className="co2-reduction-data">
      <div className="title-and-refresh">
        <p className="title">CO₂ Reductions</p>
        <Icon iconName="refresh" className="refresh-button" />
      </div>

      {reportType === 'Customer' && percentage && (
        <div className="customer">
          <Dropdown id="customer-reduction-graph-date-selection" options={dropDownOptions} onChange={handleCustomerDaysDropdownChange} />

          <div className="percentage">
            <div className="calculated-percentage">
              <p className="text">CO₂ Reduction:</p>
              <p className="value">{formatWithMinZeroFractionDigits(Math.abs(parseFloat(percentage))) + ' %'}</p>
            </div>
            <div className="calculated-percentage">
              <p className="text">Target CO₂ Reduction:</p>
              <p className="value">{co2ReductionTarget + ' %'}</p>
            </div>
          </div>

          <Co2ReductionGraph
            datasets={getCustomerCo2ReductionsGraph(selectedGhg)}
            reportType={'Customer'}
            selectedLastXDays={customerSelectedLastXDays}
            setSelectedLastXDays={setCustomerSelectedLastXDays}
          />
          <Co2ReductionTable
            reductions={getCustomerCo2Reductions(customerSelectedLastXDays, selectedGhg)}
            reportType={'Customer'}
            selectedLastXDays={customerSelectedLastXDays}
          />
        </div>
      )}
      {reportType === 'Fleet' && (
        <div className="fleet">
          <Dropdown id="fleet-reduction-graph-date-selection" options={dropDownOptions} onChange={handleFleetDaysDropdownChange} />
          <Co2ReductionGraph
            datasets={getSelectedDataFleets()}
            reportType={reportType}
            selectedLastXDays={fleetSelectedLastXDays}
            setSelectedLastXDays={setFleetSelectedLastXDays}
          />
          <Co2ReductionTable
            reductions={getCo2Reductions(getSelectedDataFleets(), fleetSelectedLastXDays)}
            reportType={reportType}
            selectedLastXDays={fleetSelectedLastXDays}
          />
        </div>
      )}

      {reportType === 'Vehicle' && (
        <div className="vehicle">
          <Dropdown id="vehicle-reduction-graph-date-selection" options={dropDownOptions} onChange={handleVehicleDaysDropdownChange} />
          <Co2ReductionGraph
            datasets={getSelectedDataVehicles()}
            reportType={reportType}
            selectedLastXDays={vehicleSelectedLastXDays}
            setSelectedLastXDays={setVehicleSelectedLastXDays}
          />
          <Co2ReductionTable
            reductions={getCo2Reductions(getSelectedDataVehicles(), vehicleSelectedLastXDays)}
            reportType={reportType}
            selectedLastXDays={vehicleSelectedLastXDays}
          />
        </div>
      )}
    </div>
  )
}

export default Co2Reduction
