import React, { useState } from 'react'
import { IAuthProps } from '../../propsInterfaces/IAuthProps'
import Navigation from '../../organisms/Navigation'
import CustomerReport from '../../organisms/homepageSections/CustomerReport'
import VehiclesReport from '../../organisms/homepageSections/VehiclesReport'
import FleetsReport from '../../organisms/homepageSections/FleetsReport'
import './Homepage.scss'
import { Dropdown } from '@bosch/react-frok'

interface PercentageOption {
  value: number
  name: string
}

const percentageOptions: PercentageOption[] = [
  { value: 99.1, name: `GLE 3.0` },
  { value: 94, name: `REDII (Basic)` },
  { value: 90.1, name: `ISO 14083:2023` },
]

const HomePage: React.FunctionComponent<IAuthProps> = ({ authInstance, authMethod }) => {
  const pageTitle = 'CO₂ Dashboard'
  const lastUpdate = '09/05/2024 16:30'
  const fuelBatch = (8.9 + 5.3) / 2
  const [co2ReductionTarget, setCo2ReductionTarget] = useState<number>(35)
  const [selectedGhg, setSelectedGhg] = useState<number>(percentageOptions.find((option) => option.name.includes('REDII'))?.value || 0)
  const [percentage, setPercentage] = useState<string>(Math.abs(1 - (fuelBatch / selectedGhg) * 100).toFixed(2))
  const selectedComparatorName = percentageOptions.find((option) => option.value === selectedGhg)?.name

  const getPercentage = (ghg: number) => {
    const percentage = 1 - (fuelBatch / ghg) * 100
    return percentage
  }

  const handleCustomerTargetChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCo2ReductionTarget(parseFloat(event.target.value))
  }

  const handleComparatorDropdownChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = parseFloat(event.target.value)
    setSelectedGhg(selectedValue)
    setPercentage(getPercentage(selectedValue).toFixed(2))
  }

  return (
    <>
      <Navigation pageTitle={pageTitle} authInstance={authInstance} authMethod={authMethod} />
      <div className="reports">
        <div className="page-background">
          <div className="last-update">
            <p className="last-update-info">Last Data Input: {lastUpdate}</p>
          </div>
        </div>
        <div className="main-results">
          <div className="target-percentage">
            <div className="target-text">
              Target CO₂ Reduction:&nbsp;
              <input
                className="target-input"
                type="number"
                min={0}
                max={100}
                step={1}
                value={co2ReductionTarget}
                onChange={handleCustomerTargetChange}
              />
              %
            </div>
          </div>
          <Dropdown
            className="main-ghg-selection"
            id="customer-percentage-selection"
            options={percentageOptions}
            label="Comparator"
            onChange={handleComparatorDropdownChange}
            value={selectedGhg}
          />
          <CustomerReport co2ReductionTarget={co2ReductionTarget} percentage={percentage} selectedGhg={selectedGhg} />
          <FleetsReport co2ReductionTarget={co2ReductionTarget} selectedComparatorName={selectedComparatorName} selectedGhg={selectedGhg} />
          <VehiclesReport co2ReductionTarget={co2ReductionTarget} selectedGhg={selectedGhg} />
        </div>
      </div>
    </>
  )
}

export default HomePage
