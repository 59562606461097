import React, { useState } from 'react'
import GraphsSelection from '../../atoms/optionBar/GraphsSelection'
import MainReportsGraph from '../../atoms/graphs/MainReportsGraph'
import { GraphType } from '../../propsInterfaces/IGraphsProps'
import {
  co2CustomerDatasets,
  co2FleetDatasets,
  co2AllVehicleDatasets,
  fuelCustomerDatasets,
  fuelFleetDatasets,
  fuelAllVehicleDatasets,
} from '../../__mocks__/data/MockGraphs'

type ReportType = 'Customer' | 'Fleet' | 'Vehicle'

interface IEmissionsAndConsumptionProps {
  reportType: ReportType
  vehicleSelectedData?: string[]
  fleetSelectedData?: string[]
}

const EmissionsAndConsumtion: React.FunctionComponent<IEmissionsAndConsumptionProps> = ({
  reportType,
  vehicleSelectedData,
  fleetSelectedData,
}) => {
  const [selectedGraph, setSelectedGraph] = useState<GraphType>('CO₂ Emissions')
  const getVehicleCo2SelectedData = () => {
    if (vehicleSelectedData) {
      const result = co2AllVehicleDatasets.filter((vehicle) =>
        vehicleSelectedData.some((substring) => vehicle.label.toLowerCase().includes(substring.toLowerCase())),
      )
      return result
    }
    return co2AllVehicleDatasets
  }

  const getVehicleFuelSelectedData = () => {
    if (vehicleSelectedData) {
      const result = fuelAllVehicleDatasets.filter((vehicle) =>
        vehicleSelectedData.some((substring) => vehicle.label.toLowerCase().includes(substring.toLowerCase())),
      )
      return result
    }
    return fuelAllVehicleDatasets
  }

  const getFleetCo2SelectedData = () => {
    if (fleetSelectedData) {
      const result = co2FleetDatasets.filter((fleet) =>
        fleetSelectedData.some((substring) => fleet.label.toLowerCase().includes(substring.toLowerCase())),
      )
      return result
    }
    return co2FleetDatasets
  }

  const getFleetFuelSelectedData = () => {
    if (fleetSelectedData) {
      const result = fuelFleetDatasets.filter((fleet) =>
        fleetSelectedData.some((substring) => fleet.label.toLowerCase().includes(substring.toLowerCase())),
      )
      return result
    }
    return fuelFleetDatasets
  }

  return (
    <div className="emissions-and-consumtion-data">
      <GraphsSelection selectedGraph={selectedGraph} setSelectedGraph={setSelectedGraph} />
      {reportType === 'Customer' && (
        <MainReportsGraph
          datasets={selectedGraph === 'CO₂ Emissions' ? co2CustomerDatasets : fuelCustomerDatasets}
          graphType={selectedGraph}
          reportType={reportType}
          vehicleDropdown={vehicleSelectedData ? vehicleSelectedData : []}
        />
      )}
      {reportType === 'Fleet' && (
        <MainReportsGraph
          datasets={selectedGraph === 'CO₂ Emissions' ? getFleetCo2SelectedData() : getFleetFuelSelectedData()}
          graphType={selectedGraph}
          reportType={reportType}
          vehicleDropdown={vehicleSelectedData ? vehicleSelectedData : []}
        />
      )}
      {reportType === 'Vehicle' && (
        <MainReportsGraph
          datasets={selectedGraph === 'CO₂ Emissions' ? getVehicleCo2SelectedData() : getVehicleFuelSelectedData()}
          graphType={selectedGraph}
          reportType={reportType}
          vehicleDropdown={vehicleSelectedData ? vehicleSelectedData : []}
        />
      )}
    </div>
  )
}

export default EmissionsAndConsumtion
