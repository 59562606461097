import React from 'react'
import { IReportProps } from '../propsInterfaces/IReportProps'
import './Co2Report.scss'
import { formatWithMinZeroFractionDigits } from '../services/formatNumber'

const Co2Report: React.FunctionComponent<IReportProps> = ({ report }) => {
  return (
    <div className="report-result">
      <div className="savings">
        <p>
          <span>CO₂ Savings Compared To Fossil:</span>
          <span className="data">{' ' + formatWithMinZeroFractionDigits(report.co2savingsComparedToFossil / 1000000) + ' tons CO₂eq'}</span>
        </p>
        <p>
          <span>Total CO₂ Emissions: </span>
          <span className="data">{' ' + formatWithMinZeroFractionDigits(report.co2emissions / 1000000) + ' tons CO₂eq'}</span>
        </p>
      </div>
      <div className="total">
        <p>
          <span>Refuel Amount: </span>
          <span className="data">{' ' + Math.round(report.refuelAmount) + ' liters'}</span>
        </p>
        <p>
          <span>Number of Refuel Events: </span>
          <span className="data">{' ' + report.nrOfRefuelEvents}</span>
        </p>
      </div>
    </div>
  )
}

export default Co2Report
