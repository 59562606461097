export const formatNumberWithFractionDigits = (value: number): string => {
  return value.toLocaleString(undefined, {
    minimumFractionDigits: 3,
    maximumFractionDigits: 3,
  })
}

export const formatWithMinZeroFractionDigits = (value: number): string => {
  return value.toLocaleString('de-DE', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 3,
  })
}

export const formatWithMaxFourFractionDigits = (value: number): string => {
  return value.toLocaleString('de-DE', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 4,
  })
}

export const formatWithMax2FractionDigits = (value: number): string => {
  return value.toLocaleString('de-DE', {
    minimumFractionDigits: 1,
    maximumFractionDigits: 2,
  })
}

export const formatStringDigitsToNumber = (formatted: string): number => {
  const cleanNumber = formatted.replace(/\./g, '').replace(',', '.')
  return parseFloat(cleanNumber)
}

export const getUnitDivision = (reportType: string, graphType: string) => {
  if (graphType === 'Refuel Amount') return 1
  else if (reportType === 'Vehicle') {
    return 1000
  } else {
    return 1000000
  }
}
