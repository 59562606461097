import React from 'react'
import { Box, Sticker } from '@bosch/react-frok'
import './Glossary.scss'
import { glossary } from '../../models/Glossary'

interface IDisclaimerProps {
  glossaryOpen: boolean
}

const Glossary: React.FunctionComponent<IDisclaimerProps> = ({ glossaryOpen }) => {
  const userClass = glossaryOpen ? 'info-open' : 'info-close'
  const glossaryContents = glossary

  const renderNorms = (norms: string[]) => {
    return norms.map((norm) => <Sticker key={norm} className="norm" background="green" label={norm} />)
  }

  return (
    <div className="side-glossary">
      <Box shadow className={userClass}>
        <div className="glossary-title">Glossary</div>
        {glossaryContents.map((content) => (
          <Box className="container" key={content.name}>
            <div className="name">{content.name}</div>
            {content.description && (
              <div className="description">
                {content.description.formula && <p className="formula">{content.description.formula}</p>}
                {content.description.text && <p className="text">{content.description.text}</p>}
              </div>
            )}
            {content.unit && (
              <div className="unit">
                Measurement Unit: <div className="unit-value">{content.unit}</div>
              </div>
            )}
            {content.name === 'Fuel Norms' && content.norms && <div className="norms">{renderNorms(content.norms)}</div>}
          </Box>
        ))}
      </Box>
    </div>
  )
}

export default Glossary
