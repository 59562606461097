import { environmentConstants } from './mainConfig'

export const AwsConfigAuth = {
  Auth: {
    Cognito: {
      region: environmentConstants.AWS_AUTH_REGION,
      userPoolClientId: environmentConstants.AWS_AUTH_CLIENT_ID,
      userPoolId: environmentConstants.AWS_AUTH_USER_ID,
      loginWith: {
        oauth: {
          domain: environmentConstants.AWS_AUTH_DOMAIN,
          scopes: [
            'email',
            'profile',
          ],
          redirectSignIn: environmentConstants.REDIRECT_URI ,
          redirectSignOut: environmentConstants.REDIRECT_URI,
          responseType: 'code',
        }
     }
    }
  }
}
