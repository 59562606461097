import CustomerReportData from '../../models/CustomerReportData'
import FleetReportData from '../../models/FleetReportData'
import VehicleReportData from '../../models/VehicleReportData'

export const fleetTableData: FleetReportData[] = [
  {
    fleetName: 'Example Logistics',
    co2emissions: 12068.67,
    nrOfRefuelEvents: 80,
    refuelAmount: 1356.03,
    co2savingsComparedToFossil: 115398.153,
  },
]

export const customerTableData: CustomerReportData[] = [
  {
    customerName: 'customer1',
    co2emissions: 12068.67,
    nrOfRefuelEvents: 80,
    refuelAmount: 1356.03,
    co2savingsComparedToFossil: 115398.153,
  },
]

export const vehicleTableData: VehicleReportData[] = [
  {
    co2emissions: 83.57,
    vehicleName: '2240006439',
    nrOfRefuelEvents: 6,
    refuelAmount: 9.39,
    co2savingsComparedToFossil: 799.089,
  },
  {
    co2emissions: 497.6,
    vehicleName: '2240006428',
    nrOfRefuelEvents: 2,
    refuelAmount: 55.91,
    co2savingsComparedToFossil: 4757.941,
  },
  {
    co2emissions: 1008.9,
    vehicleName: '2240006383',
    nrOfRefuelEvents: 6,
    refuelAmount: 113.36,
    co2savingsComparedToFossil: 9646.936,
  },
  {
    co2emissions: 334.28,
    vehicleName: '2240006440',
    nrOfRefuelEvents: 9,
    refuelAmount: 37.56,
    co2savingsComparedToFossil: 3196.356,
  },
  {
    co2emissions: 617.93,
    vehicleName: '2240006396',
    nrOfRefuelEvents: 2,
    refuelAmount: 69.43,
    co2savingsComparedToFossil: 5908.493,
  },
  {
    co2emissions: 570.4,
    vehicleName: '2240006385',
    nrOfRefuelEvents: 1,
    refuelAmount: 64.09,
    co2savingsComparedToFossil: 5454.059,
  },
  {
    co2emissions: 550.02,
    vehicleName: '2240006386',
    nrOfRefuelEvents: 1,
    refuelAmount: 61.8,
    co2savingsComparedToFossil: 5259.18,
  },
  {
    co2emissions: 301.8,
    vehicleName: '2240006390',
    nrOfRefuelEvents: 1,
    refuelAmount: 33.91,
    co2savingsComparedToFossil: 2885.741,
  },
  {
    co2emissions: 2894.99,
    vehicleName: '2240006380',
    nrOfRefuelEvents: 23,
    refuelAmount: 325.28,
    co2savingsComparedToFossil: 27681.328,
  },
  {
    co2emissions: 531.24,
    vehicleName: '2240006382',
    nrOfRefuelEvents: 1,
    refuelAmount: 59.69,
    co2savingsComparedToFossil: 5079.619,
  },
  {
    co2emissions: 1336.34,
    vehicleName: '2240006437',
    nrOfRefuelEvents: 7,
    refuelAmount: 150.15,
    co2savingsComparedToFossil: 12777.765,
  },
  {
    co2emissions: 459.6,
    vehicleName: '2240006442',
    nrOfRefuelEvents: 6,
    refuelAmount: 51.64,
    co2savingsComparedToFossil: 4394.564,
  },
  {
    co2emissions: 1533.38,
    vehicleName: '2240006433',
    nrOfRefuelEvents: 11,
    refuelAmount: 172.29,
    co2savingsComparedToFossil: 14661.879,
  },
  {
    co2emissions: 1348.62,
    vehicleName: '2240006389',
    nrOfRefuelEvents: 4,
    refuelAmount: 151.53,
    co2savingsComparedToFossil: 12895.203,
  },
]
