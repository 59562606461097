export const xsLabels = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
  'January',
]
export const co2EmissionsData = [15000, 16000, 17000, 18000, 14000, 15000, 16000, 17000, 18000, 15000, 16000, 17000, 18000]

export const co2Data = [
  { x: 'January', y: 16.3 },
  { x: 'February', y: 26.24 },
  { x: 'March', y: 15.61 },
  { x: 'April', y: 12.21 },
  { x: 'May', y: 20.94 },
  { x: 'June', y: 29.66 },
  { x: 'July', y: 17.0 },
  { x: 'August', y: 19.67 },
  { x: 'September', y: 13.32 },
  { x: 'October', y: 31.2 },
  { x: 'November', y: 20.13 },
  { x: 'December', y: 14.71 },
  { x: 'January', y: 23.01 },
]

export const co2Data1 = [
  { x: 'January', y: 11.27 },
  { x: 'February', y: 18.01 },
  { x: 'March', y: 8.79 },
  { x: 'April', y: 19.23 },
  { x: 'May', y: 14.9 },
  { x: 'June', y: 18.97 },
  { x: 'July', y: 11.81 },
  { x: 'August', y: 14.07 },
  { x: 'September', y: 18.81 },
  { x: 'October', y: 13.42 },
  { x: 'November', y: 12.77 },
  { x: 'December', y: 7.19 },
  { x: 'January', y: 10.76 },
]

export const co2DataVehicle1 = [
  {
    x: 'December 2023',
    y: 26.7,
  },
  {
    x: 'January 2024',
    y: 0,
  },
  {
    x: 'February 2024',
    y: 0,
  },
  {
    x: 'March 2024',
    y: 0,
  },
  {
    x: 'April 2024',
    y: 0,
  },
  {
    x: 'May 2024',
    y: 56.87,
  },
]

export const co2DataVehicle2 = [
  {
    x: 'December 2023',
    y: 0,
  },
  {
    x: 'January 2024',
    y: 0,
  },
  {
    x: 'February 2024',
    y: 0,
  },
  {
    x: 'March 2024',
    y: 0,
  },
  {
    x: 'April 2024',
    y: 96.83,
  },
  {
    x: 'May 2024',
    y: 237.45,
  },
]

export const co2DataTotalMay = [
  {
    x: 'May 3',
    y: 1011.57,
  },
  {
    x: 'May 6',
    y: 497.6,
  },
  {
    x: 'May 7',
    y: 294.32,
  },
  {
    x: 'May 8',
    y: 1789.08,
  },
  {
    x: 'May 9',
    y: 679.43,
  },
]

export const co2Data2 = [
  { x: 'January', y: 205.15 },
  { x: 'February', y: 188.56 },
  { x: 'March', y: 104.6 },
  { x: 'April', y: 247.78 },
  { x: 'May', y: 175.97 },
  { x: 'June', y: 129.28 },
  { x: 'July', y: 209.07 },
  { x: 'August', y: 125.65 },
  { x: 'September', y: 115.8 },
  { x: 'October', y: 148.76 },
  { x: 'November', y: 196.41 },
  { x: 'December', y: 382.3 },
  { x: 'January', y: 159.67 },
]

export const co2Data3 = [
  {
    x: 'December 2023',
    y: 26.7,
  },
  {
    x: 'January 2024',
    y: 0,
  },
  {
    x: 'February 2024',
    y: 3354.59,
  },
  {
    x: 'March 2024',
    y: 0,
  },
  {
    x: 'April 2024',
    y: 4415.38,
  },
  {
    x: 'May 2024',
    y: 4272,
  },
]

//May 9 Refuel Amount - 76.34
//May 8 Refuel Amount - 201.02
//May 7 Refuel Amount - 33.07
//May 6 Refuel Amount - 55.91
//May 3 Refuel Amount - 113.66
//ghg = REDII/GLE/ISO
//CO2 Reductions (Savings) = Refuel Amount * (ghg-8.9)

//REDII 94
export const co2ReductionData = [
  { x: 'May 9', y: 6496.534 },
  { x: 'May 8', y: 17106.8 },
  { x: 'May 7', y: 2814.257 },
  { x: 'May 6', y: 4757.941 },
  { x: 'May 5', y: 0 },
  { x: 'May 4', y: 0 },
  { x: 'May 3', y: 9672.466 },
]

//GLE Diesel 96.5
export const co2ReductionDataDieselGLE = [
  { x: 'May 9', y: 6687.384 },
  { x: 'May 8', y: 17609.352 },
  { x: 'May 7', y: 2896.932 },
  { x: 'May 6', y: 4897.716 },
  { x: 'May 5', y: 0 },
  { x: 'May 4', y: 0 },
  { x: 'May 3', y: 9956.616 },
]

//ISO Diesel 87.4
export const co2ReductionDataDieselISO = [
  { x: 'May 9', y: 5992.69 },
  { x: 'May 8', y: 15780.07 },
  { x: 'May 7', y: 2595.995 },
  { x: 'May 6', y: 4388.935 },
  { x: 'May 5', y: 0 },
  { x: 'May 4', y: 0 },
  { x: 'May 3', y: 8922.31 },
]

//GLE Gasoline 99.1
export const co2ReductionDataGasolineGLE = [
  { x: 'May 9', y: 6885.868 },
  { x: 'May 8', y: 18132.004 },
  { x: 'May 7', y: 2982.914 },
  { x: 'May 6', y: 5043.082 },
  { x: 'May 5', y: 0 },
  { x: 'May 4', y: 0 },
  { x: 'May 3', y: 10252.132 },
]

//ISO Gasoline 90.1
export const co2ReductionDataGasolineISO = [
  { x: 'May 9', y: 6198.808 },
  { x: 'May 8', y: 16322.824 },
  { x: 'May 7', y: 2685.284 },
  { x: 'May 6', y: 4539.892 },
  { x: 'May 5', y: 0 },
  { x: 'May 4', y: 0 },
  { x: 'May 3', y: 9229.192 },
]

export const co2ReductionDataFleet = [
  {
    label: 'Example Logistics',
    data: [
      { x: 'May 9', y: 6496.534 },
      { x: 'May 8', y: 17106.8 },
      { x: 'May 7', y: 2814.257 },
      { x: 'May 6', y: 4757.941 },
      { x: 'May 5', y: 0 },
      { x: 'May 4', y: 0 },
      { x: 'May 3', y: 9672.466 },
    ],
  },
]

export const co2ReductionDataCustomer = [
  {
    label: 'Example Logistics',
    data: [
      { x: 'May 9', y: 6496.534 },
      { x: 'May 8', y: 17106.8 },
      { x: 'May 7', y: 2814.257 },
      { x: 'May 6', y: 4757.941 },
      { x: 'May 5', y: 0 },
      { x: 'May 4', y: 0 },
      { x: 'May 3', y: 9672.466 },
    ],
  },
]

export const co2ReductionDataCustomerDieselISO = [
  {
    label: 'Example Logistics',
    data: co2ReductionDataDieselISO,
  },
]

export const co2ReductionDataCustomerDieselGLE = [
  {
    label: 'Example Logistics',
    data: co2ReductionDataDieselGLE,
  },
]

export const co2ReductionDataCustomerGasolineISO = [
  {
    label: 'Example Logistics',
    data: co2ReductionDataGasolineISO,
  },
]

export const co2ReductionDataCustomerGasolineGLE = [
  {
    label: 'Example Logistics',
    data: co2ReductionDataGasolineGLE,
  },
]

export const emissionsDataMayCustomer = [
  {
    label: 'Example Logistics',
    data: [
      { x: 'May 9', y: 679.43 },
      { x: 'May 8', y: 2468.51 },
      { x: 'May 7', y: 294.32 },
      { x: 'May 6', y: 497.6 },
      { x: 'May 5', y: 0 },
      { x: 'May 4', y: 0 },
      { x: 'May 3', y: 1012.02 },
    ],
  },
]

export const co2ReductionDataVehicle1 = [
  { x: 'May 9', y: 0 },
  { x: 'May 8', y: 0 },
  { x: 'May 7', y: 543.789 },
  { x: 'May 6', y: 0 },
  { x: 'May 5', y: 0 },
  { x: 'May 4', y: 0 },
  { x: 'May 3', y: 0 },
]

export const co2ReductionDataVehicle2 = [
  { x: 'May 9', y: 0 },
  { x: 'May 8', y: 0 },
  { x: 'May 7', y: 2270.468 },
  { x: 'May 6', y: 0 },
  { x: 'May 5', y: 0 },
  { x: 'May 4', y: 0 },
  { x: 'May 3', y: 0 },
]

export const co2ReductionsCustomerDatasets = [
  {
    label: 'Example Logistics',
    data: co2ReductionData,
  },
]

export const co2ReductionsCustomerDatasetsGasolineISO = [
  {
    label: 'Example Logistics',
    data: co2ReductionDataGasolineISO,
  },
]

export const co2ReductionsCustomerDatasetsGasolineGLE = [
  {
    label: 'Example Logistics',
    data: co2ReductionDataGasolineGLE,
  },
]

export const co2ReductionsCustomerDatasetsDieselGLE = [
  {
    label: 'Example Logistics',
    data: co2ReductionDataDieselGLE,
  },
]

export const co2ReductionsCustomerDatasetsDieselISO = [
  {
    label: 'Example Logistics',
    data: co2ReductionDataDieselISO,
  },
]

export const co2ReductionsFleetDatasets = [
  {
    label: 'Example Logistics',
    data: co2ReductionData,
  },
]

export const co2ReductionsVehicleDatasets = [
  {
    label: '2240006439',
    data: co2ReductionDataVehicle1,
  },
  {
    label: '2240006440',
    data: co2ReductionDataVehicle2,
  },
]

export const co2AllReductionsVehicleDatasets = [
  {
    label: '2240006380',
    data: [
      { x: 'May 9', y: 0 },
      { x: 'May 8', y: 0 },
      { x: 'May 7', y: 0 },
      { x: 'May 6', y: 0 },
      { x: 'May 5', y: 0 },
      { x: 'May 4', y: 0 },
      { x: 'May 3', y: 0 },
    ],
  },
  {
    label: '2240006440',
    data: [
      { x: 'May 9', y: 0 },
      { x: 'May 8', y: 0 },
      { x: 'May 7', y: 2270.468 },
      { x: 'May 6', y: 0 },
      { x: 'May 5', y: 0 },
      { x: 'May 4', y: 0 },
      { x: 'May 3', y: 0 },
    ],
  },
  {
    label: '2240006439',
    data: [
      { x: 'May 9', y: 0 },
      { x: 'May 8', y: 0 },
      { x: 'May 7', y: 543.789 },
      { x: 'May 6', y: 0 },
      { x: 'May 5', y: 0 },
      { x: 'May 4', y: 0 },
      { x: 'May 3', y: 0 },
    ],
  },
  {
    label: '2240006442',
    data: [
      { x: 'May 9', y: 0 },
      { x: 'May 8', y: 0 },
      { x: 'May 7', y: 0 },
      { x: 'May 6', y: 0 },
      { x: 'May 5', y: 0 },
      { x: 'May 4', y: 0 },
      { x: 'May 3', y: 0 },
    ],
  },
  {
    label: '2240006382',
    data: [
      { x: 'May 9', y: 0 },
      { x: 'May 8', y: 0 },
      { x: 'May 7', y: 0 },
      { x: 'May 6', y: 0 },
      { x: 'May 5', y: 0 },
      { x: 'May 4', y: 0 },
      { x: 'May 3', y: 0 },
    ],
  },
  {
    label: '2240006433',
    data: [
      { x: 'May 9', y: 0 },
      { x: 'May 8', y: 1532.651 },
      { x: 'May 7', y: 0 },
      { x: 'May 6', y: 0 },
      { x: 'May 5', y: 0 },
      { x: 'May 4', y: 0 },
      { x: 'May 3', y: 3498.461 },
    ],
  },
  {
    label: '2240006389',
    data: [
      { x: 'May 9', y: 0 },
      { x: 'May 8', y: 2441.519 },
      { x: 'May 7', y: 0 },
      { x: 'May 6', y: 0 },
      { x: 'May 5', y: 0 },
      { x: 'May 4', y: 0 },
      { x: 'May 3', y: 4312.868 },
    ],
  },
  {
    label: '2240006383',
    data: [
      { x: 'May 9', y: 0 },
      { x: 'May 8', y: 4261.808 },
      { x: 'May 7', y: 0 },
      { x: 'May 6', y: 0 },
      { x: 'May 5', y: 0 },
      { x: 'May 4', y: 0 },
      { x: 'May 3', y: 1861.137 },
    ],
  },
  {
    label: '2240006437',
    data: [
      { x: 'May 9', y: 6496.534 },
      { x: 'May 8', y: 0 },
      { x: 'May 7', y: 0 },
      { x: 'May 6', y: 0 },
      { x: 'May 5', y: 0 },
      { x: 'May 4', y: 0 },
      { x: 'May 3', y: 0 },
    ],
  },
  {
    label: '2240006441',
    data: [
      { x: 'May 9', y: 0 },
      { x: 'May 8', y: 0 },
      { x: 'May 7', y: 0 },
      { x: 'May 6', y: 0 },
      { x: 'May 5', y: 0 },
      { x: 'May 4', y: 0 },
      { x: 'May 3', y: 0 },
    ],
  },
  {
    label: '2240006428',
    data: [
      { x: 'May 9', y: 0 },
      { x: 'May 8', y: 0 },
      { x: 'May 7', y: 0 },
      { x: 'May 6', y: 4757.941 },
      { x: 'May 5', y: 0 },
      { x: 'May 4', y: 0 },
      { x: 'May 3', y: 0 },
    ],
  },
  {
    label: '2240006386',
    data: [
      { x: 'May 9', y: 0 },
      { x: 'May 8', y: 0 },
      { x: 'May 7', y: 0 },
      { x: 'May 6', y: 0 },
      { x: 'May 5', y: 0 },
      { x: 'May 4', y: 0 },
      { x: 'May 3', y: 0 },
    ],
  },
  {
    label: '2240006396',
    data: [
      { x: 'May 9', y: 0 },
      { x: 'May 8', y: 3416.765 },
      { x: 'May 7', y: 0 },
      { x: 'May 6', y: 0 },
      { x: 'May 5', y: 0 },
      { x: 'May 4', y: 0 },
      { x: 'May 3', y: 0 },
    ],
  },
  {
    label: '2240006390',
    data: [
      { x: 'May 9', y: 0 },
      { x: 'May 8', y: 0 },
      { x: 'May 7', y: 0 },
      { x: 'May 6', y: 0 },
      { x: 'May 5', y: 0 },
      { x: 'May 4', y: 0 },
      { x: 'May 3', y: 0 },
    ],
  },
  {
    label: '2240006385',
    data: [
      { x: 'May 9', y: 0 },
      { x: 'May 8', y: 5454.059 },
      { x: 'May 7', y: 0 },
      { x: 'May 6', y: 0 },
      { x: 'May 5', y: 0 },
      { x: 'May 4', y: 0 },
      { x: 'May 3', y: 0 },
    ],
  },
]

export const co2AllEmissionsVehicleDatasets = [
  {
    label: '2240006380',
    data: [
      { x: 'May 9', y: 0 },
      { x: 'May 8', y: 0 },
      { x: 'May 7', y: 0 },
      { x: 'May 6', y: 0 },
      { x: 'May 5', y: 0 },
      { x: 'May 4', y: 0 },
      { x: 'May 3', y: 0 },
    ],
  },
  {
    label: '2240006437',
    data: [
      { x: 'May 9', y: 679.43 },
      { x: 'May 8', y: 0 },
      { x: 'May 7', y: 0 },
      { x: 'May 6', y: 0 },
      { x: 'May 5', y: 0 },
      { x: 'May 4', y: 0 },
      { x: 'May 3', y: 0 },
    ],
  },
  {
    label: '2240006440',
    data: [
      { x: 'May 9', y: 0 },
      { x: 'May 8', y: 0 },
      { x: 'May 7', y: 237.45 },
      { x: 'May 6', y: 0 },
      { x: 'May 5', y: 0 },
      { x: 'May 4', y: 0 },
      { x: 'May 3', y: 0 },
    ],
  },
  {
    label: '2240006442',
    data: [
      { x: 'May 9', y: 0 },
      { x: 'May 8', y: 0 },
      { x: 'May 7', y: 0 },
      { x: 'May 6', y: 0 },
      { x: 'May 5', y: 0 },
      { x: 'May 4', y: 0 },
      { x: 'May 3', y: 0 },
    ],
  },
  {
    label: '2240006382',
    data: [
      { x: 'May 9', y: 0 },
      { x: 'May 8', y: 0 },
      { x: 'May 7', y: 0 },
      { x: 'May 6', y: 0 },
      { x: 'May 5', y: 0 },
      { x: 'May 4', y: 0 },
      { x: 'May 3', y: 0 },
    ],
  },
  {
    label: '2240006396',
    data: [
      { x: 'May 9', y: 0 },
      { x: 'May 8', y: 357.34 },
      { x: 'May 7', y: 0 },
      { x: 'May 6', y: 0 },
      { x: 'May 5', y: 0 },
      { x: 'May 4', y: 0 },
      { x: 'May 3', y: 0 },
    ],
  },
  {
    label: '2240006385',
    data: [
      { x: 'May 9', y: 0 },
      { x: 'May 8', y: 570.4 },
      { x: 'May 7', y: 0 },
      { x: 'May 6', y: 0 },
      { x: 'May 5', y: 0 },
      { x: 'May 4', y: 0 },
      { x: 'May 3', y: 0 },
    ],
  },
  {
    label: '2240006386',
    data: [
      { x: 'May 9', y: 0 },
      { x: 'May 8', y: 0 },
      { x: 'May 7', y: 0 },
      { x: 'May 6', y: 0 },
      { x: 'May 5', y: 0 },
      { x: 'May 4', y: 0 },
      { x: 'May 3', y: 0 },
    ],
  },
  {
    label: '2240006389',
    data: [
      { x: 'May 9', y: 0 },
      { x: 'May 8', y: 255.34 },
      { x: 'May 7', y: 0 },
      { x: 'May 6', y: 0 },
      { x: 'May 5', y: 0 },
      { x: 'May 4', y: 0 },
      { x: 'May 3', y: 451.05 },
    ],
  },
  {
    label: '2240006433',
    data: [
      { x: 'May 9', y: 0 },
      { x: 'May 8', y: 160.29 },
      { x: 'May 7', y: 0 },
      { x: 'May 6', y: 0 },
      { x: 'May 5', y: 0 },
      { x: 'May 4', y: 0 },
      { x: 'May 3', y: 365.88 },
    ],
  },
  {
    label: '2240006383',
    data: [
      { x: 'May 9', y: 0 },
      { x: 'May 8', y: 445.71 },
      { x: 'May 7', y: 0 },
      { x: 'May 6', y: 0 },
      { x: 'May 5', y: 0 },
      { x: 'May 4', y: 0 },
      { x: 'May 3', y: 194.64 },
    ],
  },
  {
    label: '2240006390',
    data: [
      { x: 'May 9', y: 0 },
      { x: 'May 8', y: 0 },
      { x: 'May 7', y: 0 },
      { x: 'May 6', y: 0 },
      { x: 'May 5', y: 0 },
      { x: 'May 4', y: 0 },
      { x: 'May 3', y: 0 },
    ],
  },
  {
    label: '2240006428',
    data: [
      { x: 'May 9', y: 0 },
      { x: 'May 8', y: 0 },
      { x: 'May 7', y: 497.6 },
      { x: 'May 6', y: 0 },
      { x: 'May 5', y: 0 },
      { x: 'May 4', y: 0 },
      { x: 'May 3', y: 0 },
    ],
  },
]

export const co2CustomerDatasets = [
  {
    label: 'Example Logistics',
    data: co2Data3,
  },
]

export const co2FleetDatasets = [
  {
    label: 'Example Logistics',
    data: co2Data3,
  },
]

export const co2VehicleDatasets = [
  {
    label: '2240006439',
    data: co2DataVehicle1,
  },
  {
    label: '2240006440',
    data: co2DataVehicle2,
  },
]

export const co2AllVehicleDatasets = [
  {
    label: '2240006380',
    data: [
      { x: 'December 2023', y: 0 },
      { x: 'January 2024', y: 0 },
      { x: 'February 2024', y: 3659.92 },
      { x: 'March 2024', y: 0 },
      { x: 'April 2024', y: 0 },
      { x: 'May 2024', y: 0 },
    ],
  },
  {
    label: '2240006440',
    data: [
      { x: 'December 2023', y: 0 },
      { x: 'January 2024', y: 0 },
      { x: 'February 2024', y: 0 },
      { x: 'March 2024', y: 0 },
      { x: 'April 2024', y: 96.83 },
      { x: 'May 2024', y: 237.45 },
    ],
  },
  {
    label: '2240006439',
    data: [
      { x: 'December 2023', y: 26.7 },
      { x: 'January 2024', y: 0 },
      { x: 'February 2024', y: 0 },
      { x: 'March 2024', y: 0 },
      { x: 'April 2024', y: 0 },
      { x: 'May 2024', y: 56.87 },
    ],
  },
  {
    label: '2240006442',
    data: [
      { x: 'December 2023', y: 0 },
      { x: 'January 2024', y: 0 },
      { x: 'February 2024', y: 459.6 },
      { x: 'March 2024', y: 0 },
      { x: 'April 2024', y: 0 },
      { x: 'May 2024', y: 0 },
    ],
  },
  {
    label: '2240006382',
    data: [
      { x: 'December 2023', y: 0 },
      { x: 'January 2024', y: 0 },
      { x: 'February 2024', y: 0 },
      { x: 'March 2024', y: 0 },
      { x: 'April 2024', y: 531.24 },
      { x: 'May 2024', y: 0 },
    ],
  },
  {
    label: '2240006433',
    data: [
      { x: 'December 2023', y: 0 },
      { x: 'January 2024', y: 0 },
      { x: 'February 2024', y: 0 },
      { x: 'March 2024', y: 0 },
      { x: 'April 2024', y: 1007.21 },
      { x: 'May 2024', y: 526.27 },
    ],
  },
  {
    label: '2240006389',
    data: [
      { x: 'December 2023', y: 0 },
      { x: 'January 2024', y: 0 },
      { x: 'February 2024', y: 0 },
      { x: 'March 2024', y: 0 },
      { x: 'April 2024', y: 642.23 },
      { x: 'May 2024', y: 706.39 },
    ],
  },
  {
    label: '2240006383',
    data: [
      { x: 'December 2023', y: 0 },
      { x: 'January 2024', y: 0 },
      { x: 'February 2024', y: 0 },
      { x: 'March 2024', y: 0 },
      { x: 'April 2024', y: 368.55 },
      { x: 'May 2024', y: 640.35 },
    ],
  },
  {
    label: '2240006437',
    data: [
      { x: 'December 2023', y: 0 },
      { x: 'January 2024', y: 0 },
      { x: 'February 2024', y: 0 },
      { x: 'March 2024', y: 0 },
      { x: 'April 2024', y: 656.91 },
      { x: 'May 2024', y: 679.43 },
    ],
  },
  {
    label: '2240006441',
    data: [
      { x: 'December 2023', y: 0 },
      { x: 'January 2024', y: 0 },
      { x: 'February 2024', y: 0 },
      { x: 'March 2024', y: 0 },
      { x: 'April 2024', y: 0 },
      { x: 'May 2024', y: 0 },
    ],
  },
  {
    label: '2240006428',
    data: [
      { x: 'December 2023', y: 0 },
      { x: 'January 2024', y: 0 },
      { x: 'February 2024', y: 0 },
      { x: 'March 2024', y: 0 },
      { x: 'April 2024', y: 0 },
      { x: 'May 2024', y: 497.6 },
    ],
  },
  {
    label: '2240006386',
    data: [
      { x: 'December 2023', y: 0 },
      { x: 'January 2024', y: 0 },
      { x: 'February 2024', y: 0 },
      { x: 'March 2024', y: 0 },
      { x: 'April 2024', y: 550.02 },
      { x: 'May 2024', y: 0 },
    ],
  },
  {
    label: '2240006396',
    data: [
      { x: 'December 2023', y: 0 },
      { x: 'January 2024', y: 0 },
      { x: 'February 2024', y: 0 },
      { x: 'March 2024', y: 0 },
      { x: 'April 2024', y: 260.59 },
      { x: 'May 2024', y: 357.34 },
    ],
  },
  {
    label: '2240006390',
    data: [
      { x: 'December 2023', y: 0 },
      { x: 'January 2024', y: 0 },
      { x: 'February 2024', y: 0 },
      { x: 'March 2024', y: 0 },
      { x: 'April 2024', y: 301.8 },
      { x: 'May 2024', y: 0 },
    ],
  },
  {
    label: '2240006385',
    data: [
      { x: 'December 2023', y: 0 },
      { x: 'January 2024', y: 0 },
      { x: 'February 2024', y: 0 },
      { x: 'March 2024', y: 0 },
      { x: 'April 2024', y: 0 },
      { x: 'May 2024', y: 570.4 },
    ],
  },
]

export const fuelAllVehicleDatasets = [
  {
    label: '2240006380',
    data: [
      { x: 'December 2023', y: 0 },
      { x: 'January 2024', y: 0 },
      { x: 'February 2024', y: 238.87 },
      { x: 'March 2024', y: 0 },
      { x: 'April 2024', y: 0 },
      { x: 'May 2024', y: 0 },
    ],
  },
  {
    label: '2240006440',
    data: [
      { x: 'December 2023', y: 0 },
      { x: 'January 2024', y: 0 },
      { x: 'February 2024', y: 0 },
      { x: 'March 2024', y: 0 },
      { x: 'April 2024', y: 10.88 },
      { x: 'May 2024', y: 26.68 },
    ],
  },
  {
    label: '2240006439',
    data: [
      { x: 'December 2023', y: 3 },
      { x: 'January 2024', y: 0 },
      { x: 'February 2024', y: 0 },
      { x: 'March 2024', y: 0 },
      { x: 'April 2024', y: 0 },
      { x: 'May 2024', y: 6.39 },
    ],
  },
  {
    label: '2240006442',
    data: [
      { x: 'December 2023', y: 0 },
      { x: 'January 2024', y: 0 },
      { x: 'February 2024', y: 51.64 },
      { x: 'March 2024', y: 0 },
      { x: 'April 2024', y: 0 },
      { x: 'May 2024', y: 0 },
    ],
  },
  {
    label: '2240006433',
    data: [
      { x: 'December 2023', y: 0 },
      { x: 'January 2024', y: 0 },
      { x: 'February 2024', y: 0 },
      { x: 'March 2024', y: 0 },
      { x: 'April 2024', y: 113.17 },
      { x: 'May 2024', y: 59.12 },
    ],
  },
  {
    label: '2240006389',
    data: [
      { x: 'December 2023', y: 0 },
      { x: 'January 2024', y: 0 },
      { x: 'February 2024', y: 0 },
      { x: 'March 2024', y: 0 },
      { x: 'April 2024', y: 72.16 },
      { x: 'May 2024', y: 79.57 },
    ],
  },
  {
    label: '2240006383',
    data: [
      { x: 'December 2023', y: 0 },
      { x: 'January 2024', y: 0 },
      { x: 'February 2024', y: 0 },
      { x: 'March 2024', y: 0 },
      { x: 'April 2024', y: 41.41 },
      { x: 'May 2024', y: 71.95 },
    ],
  },
  {
    label: '2240006437',
    data: [
      { x: 'December 2023', y: 0 },
      { x: 'January 2024', y: 0 },
      { x: 'February 2024', y: 0 },
      { x: 'March 2024', y: 0 },
      { x: 'April 2024', y: 73.81 },
      { x: 'May 2024', y: 76.34 },
    ],
  },
  {
    label: '2240006441',
    data: [
      { x: 'December 2023', y: 0 },
      { x: 'January 2024', y: 0 },
      { x: 'February 2024', y: 0 },
      { x: 'March 2024', y: 0 },
      { x: 'April 2024', y: 0 },
      { x: 'May 2024', y: 0 },
    ],
  },
  {
    label: '2240006428',
    data: [
      { x: 'December 2023', y: 0 },
      { x: 'January 2024', y: 0 },
      { x: 'February 2024', y: 0 },
      { x: 'March 2024', y: 0 },
      { x: 'April 2024', y: 0 },
      { x: 'May 2024', y: 55.91 },
    ],
  },
  {
    label: '2240006386',
    data: [
      { x: 'December 2023', y: 0 },
      { x: 'January 2024', y: 0 },
      { x: 'February 2024', y: 0 },
      { x: 'March 2024', y: 0 },
      { x: 'April 2024', y: 61.8 },
      { x: 'May 2024', y: 0 },
    ],
  },
  {
    label: '2240006396',
    data: [
      { x: 'December 2023', y: 0 },
      { x: 'January 2024', y: 0 },
      { x: 'February 2024', y: 0 },
      { x: 'March 2024', y: 0 },
      { x: 'April 2024', y: 29.28 },
      { x: 'May 2024', y: 40.15 },
    ],
  },
  {
    label: '2240006390',
    data: [
      { x: 'December 2023', y: 0 },
      { x: 'January 2024', y: 0 },
      { x: 'February 2024', y: 0 },
      { x: 'March 2024', y: 0 },
      { x: 'April 2024', y: 33.91 },
      { x: 'May 2024', y: 0 },
    ],
  },
  {
    label: '2240006385',
    data: [
      { x: 'December 2023', y: 0 },
      { x: 'January 2024', y: 0 },
      { x: 'February 2024', y: 0 },
      { x: 'March 2024', y: 0 },
      { x: 'April 2024', y: 0 },
      { x: 'May 2024', y: 64.09 },
    ],
  },
  {
    label: '2240006382',
    data: [
      { x: 'December 2023', y: 0 },
      { x: 'January 2024', y: 0 },
      { x: 'February 2024', y: 0 },
      { x: 'March 2024', y: 0 },
      { x: 'April 2024', y: 59.69 },
      { x: 'May 2024', y: 0 },
    ],
  },
]

export const fuelData = [
  { x: 'January', y: 0.35 },
  { x: 'February', y: 0.25 },
  { x: 'March', y: 0.2 },
  { x: 'April', y: 0.39 },
  { x: 'May', y: 0.2 },
  { x: 'June', y: 0.39 },
  { x: 'July', y: 0.21 },
  { x: 'August', y: 0.41 },
  { x: 'September', y: 0.27 },
  { x: 'October', y: 0.41 },
  { x: 'November', y: 0.26 },
  { x: 'December', y: 0.46 },
  { x: 'January', y: 0.2 },
]

export const fuelDataTotal = [
  {
    x: 'December 2023',
    y: 3,
  },
  {
    x: 'January 2024',
    y: 0,
  },
  {
    x: 'February 2024',
    y: 376.92,
  },
  {
    x: 'March 2024',
    y: 0,
  },
  {
    x: 'April 2024',
    y: 496.11,
  },
  {
    x: 'May 2024',
    y: 480,
  },
]

export const fuelDataTotalMay = [
  {
    x: 'May 3',
    y: 113.66,
  },
  {
    x: 'May 6',
    y: 55.91,
  },
  {
    x: 'May 7',
    y: 33.07,
  },
  {
    x: 'May 8',
    y: 201.02,
  },
  {
    x: 'May 9',
    y: 76.34,
  },
]

export const fuelDataVehicle1 = [
  {
    x: 'December 2023',
    y: 3,
  },
  {
    x: 'January 2024',
    y: 0,
  },
  {
    x: 'February 2024',
    y: 0,
  },
  {
    x: 'March 2024',
    y: 0,
  },
  {
    x: 'April 2024',
    y: 0,
  },
  {
    x: 'May 2024',
    y: 6.39,
  },
]

export const fuelDataVehicle2 = [
  {
    x: 'December 2023',
    y: 0,
  },
  {
    x: 'January 2024',
    y: 0,
  },
  {
    x: 'February 2024',
    y: 0,
  },
  {
    x: 'March 2024',
    y: 0,
  },
  {
    x: 'April 2024',
    y: 10.88,
  },
  {
    x: 'May 2024',
    y: 26.68,
  },
]

export const fuelData1 = [
  { x: 'January', y: 2.57 },
  { x: 'February', y: 2.58 },
  { x: 'March', y: 2.07 },
  { x: 'April', y: 1.78 },
  { x: 'May', y: 2.96 },
  { x: 'June', y: 1.85 },
  { x: 'July', y: 2.71 },
  { x: 'August', y: 1.53 },
  { x: 'September', y: 1.71 },
  { x: 'October', y: 2.7 },
  { x: 'November', y: 2.54 },
  { x: 'December', y: 2.09 },
  { x: 'January', y: 1.91 },
]

export const fuelData2 = [
  { x: 'January', y: 79.47 },
  { x: 'February', y: 73.02 },
  { x: 'March', y: 52.85 },
  { x: 'April', y: 41.94 },
  { x: 'May', y: 43.82 },
  { x: 'June', y: 71.45 },
  { x: 'July', y: 35.29 },
  { x: 'August', y: 64.42 },
  { x: 'September', y: 27.44 },
  { x: 'October', y: 54.97 },
  { x: 'November', y: 57.97 },
  { x: 'December', y: 59.62 },
  { x: 'January', y: 32.74 },
]

export const fuelFleetDatasets = [
  {
    label: 'Example Logistics Refuel Amount',
    data: fuelDataTotal,
  },
]

export const fuelVehicleDatasets = [
  {
    label: '2240006439 Refuel Amount',
    data: fuelDataVehicle1,
  },
  {
    label: '2240006440 Refuel Amount',
    data: fuelDataVehicle2,
  },
]

export const fuelCustomerDatasets = [
  {
    label: 'Example Logistics Refuel Amount',
    data: fuelDataTotal,
  },
]

export const vehicleMileageFossil = [
  { x: 2500, y: 140 },
  { x: 2550, y: 140 },
  { x: 2600, y: 150 },
  { x: 2650, y: 150 },
  { x: 2700, y: 130 },
  { x: 2750, y: 130 },
  { x: 2800, y: 120 },
  { x: 2850, y: 120 },
  { x: 2900, y: 140 },
]

export const vehicleMileageRecognized = [
  { x: 2500, y: 100 },
  { x: 2550, y: 100 },
  { x: 2600, y: 140 },
  { x: 2650, y: 140 },
  { x: 2700, y: 60 },
  { x: 2750, y: 60 },
  { x: 2800, y: 100 },
  { x: 2850, y: 100 },
  { x: 2900, y: 80 },
]

export const vehicleMileageSecondFossil = [
  { x: 2500, y: 140 },
  { x: 2550, y: 140 },
  { x: 2600, y: 160 },
  { x: 2650, y: 160 },
  { x: 2700, y: 120 },
  { x: 2750, y: 120 },
  { x: 2800, y: 100 },
  { x: 2850, y: 100 },
  { x: 2900, y: 110 },
]

export const vehicleMileageSecondRecognized = [
  { x: 2500, y: 130 },
  { x: 2550, y: 130 },
  { x: 2600, y: 155 },
  { x: 2650, y: 155 },
  { x: 2700, y: 60 },
  { x: 2750, y: 60 },
  { x: 2800, y: 40 },
  { x: 2850, y: 40 },
  { x: 2900, y: 45 },
]

export const vehicleMileageThirdFossil = [
  { x: 2500, y: 160 },
  { x: 2550, y: 160 },
  { x: 2600, y: 175 },
  { x: 2650, y: 175 },
  { x: 2700, y: 70 },
  { x: 2750, y: 70 },
  { x: 2800, y: 50 },
  { x: 2850, y: 50 },
  { x: 2900, y: 45 },
]

export const vehicleMileageThirdRecognized = [
  { x: 2500, y: 140 },
  { x: 2550, y: 140 },
  { x: 2600, y: 110 },
  { x: 2650, y: 110 },
  { x: 2700, y: 20 },
  { x: 2750, y: 20 },
  { x: 2800, y: 10 },
  { x: 2850, y: 10 },
  { x: 2900, y: 5 },
]

export const vehicleMileageForthFossil = [
  { x: 2500, y: 160 },
  { x: 2550, y: 160 },
  { x: 2600, y: 175 },
  { x: 2650, y: 175 },
  { x: 2700, y: 170 },
  { x: 2750, y: 170 },
  { x: 2800, y: 50 },
  { x: 2850, y: 50 },
  { x: 2900, y: 75 },
]

export const vehicleMileageForthRecognized = [
  { x: 2500, y: 140 },
  { x: 2550, y: 140 },
  { x: 2600, y: 120 },
  { x: 2650, y: 120 },
  { x: 2700, y: 110 },
  { x: 2750, y: 110 },
  { x: 2800, y: 10 },
  { x: 2850, y: 10 },
  { x: 2900, y: 15 },
]

export const vehicleMileage = [
  {
    label: 'kg/km Fossil Estimations',
    data: vehicleMileageFossil,
  },
  {
    label: 'kg/km Recognized Fuel',
    data: vehicleMileageRecognized,
  },
]

export const vehicleMileageSecond = [
  {
    label: 'kg/km Fossil Estimations',
    data: vehicleMileageSecondFossil,
  },
  {
    label: 'kg/km Recognized Fuel',
    data: vehicleMileageSecondRecognized,
  },
]

export const vehicleMileageThird = [
  {
    label: 'kg/km Fossil Estimations',
    data: vehicleMileageThirdFossil,
  },
  {
    label: 'kg/km Recognized Fuel',
    data: vehicleMileageThirdRecognized,
  },
]

export const vehicleMileageForth = [
  {
    label: 'kg/km Fossil Estimations',
    data: vehicleMileageForthFossil,
  },
  {
    label: 'kg/km Recognized Fuel',
    data: vehicleMileageForthRecognized,
  },
]
