import React, { useEffect, useState } from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import './MainSections.scss'
import DatePicker from 'react-datepicker'
import FleetReportData from '../../models/FleetReportData'
import Co2FleetReportTable from '../../atoms/tables/reports/Co2FleetReportTable'
import { fleetTableData } from '../../__mocks__/data/MockReport'
import { Button, Chip, PageIndicator } from '@bosch/react-frok'
import EmissionsAndConsumtion from '../../molecules/EmissionsAndConsumption/EmissionsAndConsumtion'
import SelectionDialog from '../../molecules/SelectionDialog/SelectionDialog'
import { allVehicles, fleetNamesAndIds } from '../../__mocks__/data/MockSlectables'
import Co2Reduction from '../../molecules/Co2Reduction/Co2Reduction'
import VehicleForFleetDialog from '../../molecules/VehicleForFleet/VehicleForFleetDialog'
import { vehicleIds } from '../../__mocks__/data/MockSlectables'
import { IReportAnalysisProps } from '../../propsInterfaces/IReportProps'
import FleetAreaChart from '../../atoms/graphs/fleet/FleetAreaChart'
import FleetPieChart from '../../atoms/graphs/fleet/FleetPieChart'
import { mockReportsBasedOnGhg } from '../../__mocks__/generators/ReportGenerator'

const FleetsReport: React.FunctionComponent<IReportAnalysisProps> = ({ co2ReductionTarget, selectedComparatorName, selectedGhg }) => {
  const currentDate = new Date()
  const firstDate = new Date(2023, 11, 22)
  const firstOfJanuary = new Date(2024, 0, 1)
  const lastOfDecember = new Date(2024, 11, 31)
  const [fleetStartDate, setFleetStartDate] = useState<Date | null>(firstDate)
  const [fleetEndDate, setFleetEndDate] = useState<Date | null>(currentDate)
  const [fleetChartStartDate, setFleetChartStartDate] = useState<Date | null>(firstOfJanuary)
  const [fleetChartEndDate, setFleetChartEndDate] = useState<Date | null>(lastOfDecember)
  const [dialogOpen, setDialogOpen] = useState<boolean>(false)
  const [vehiclesForFleetDialogOpen, setVehiclesForFleetDialogOpen] = useState<boolean>(false)
  const [checkedSelection, setCheckedSelection] = useState<string[]>(['1'])
  const [vehicleCheckedSelection, setVehicleCheckedSelection] = useState<string[]>(allVehicles)
  const [fleetReports, setFleetReports] = useState<FleetReportData[]>(fleetTableData)
  const [checkedSelectionNames, setCheckedSelectionNames] = useState<string[]>(['Example Logistics'])
  const numberOfPages = 1

  const handleFleetDateChange = (range: [Date | null, Date | null]) => {
    const [startDate, endDate] = range
    setFleetStartDate(startDate)
    setFleetEndDate(endDate)
  }

  const handleFleetChartDateChange = (range: [Date | null, Date | null]) => {
    const [startDate, endDate] = range
    setFleetChartStartDate(startDate)
    setFleetChartEndDate(endDate)
  }

  const handleDialogClick = () => {
    setDialogOpen(!dialogOpen)
  }

  const handleVehicleDialogClick = () => {
    setVehiclesForFleetDialogOpen(!vehiclesForFleetDialogOpen)
  }

  const handleClose = (selected: string, index: number) => {
    const newCheckedSelection = checkedSelection.filter((val, i) => (i !== index ? val !== selected : val === selected))
    setCheckedSelectionNames((prev) => prev.filter((val) => val !== selected))
    setCheckedSelection(newCheckedSelection)
  }

  const filteredFleetReports =
    checkedSelectionNames.length !== 0 ? fleetReports.filter((fleet) => checkedSelectionNames.includes(fleet.fleetName)) : []

  useEffect(() => {
    setFleetReports(mockReportsBasedOnGhg(fleetTableData, selectedGhg) as FleetReportData[])
  }, [selectedGhg])

  return (
    <div className="main-section">
      <p className="reports-paragraph">Fleet Report </p>
      <Button
        className="select-data-button"
        label="Fleet"
        icon={{
          iconName: 'add',
          title: 'Select fleets',
        }}
        onClick={handleDialogClick}
      />
      {checkedSelectionNames.map((selected, index) => (
        <Chip
          buttonClose
          key={'fleet' + selected}
          chipLabelId={'fleet-selected-chip-' + selected}
          onClick={() => {
            handleClose(selected, index)
          }}
          label={selected}
        />
      ))}
      {filteredFleetReports.length !== 0 && (
        <div className="main-graphs">
          <EmissionsAndConsumtion reportType="Fleet" fleetSelectedData={checkedSelectionNames} />
          <Co2Reduction reportType="Fleet" co2ReductionTarget={co2ReductionTarget} selectedGhg={selectedGhg} />
        </div>
      )}
      {dialogOpen && (
        <SelectionDialog
          selectableNames={fleetNamesAndIds.map((fleet) => fleet.name)}
          selectableIds={fleetNamesAndIds.map((fleet) => fleet.id)}
          setDialogOpen={setDialogOpen}
          setCheckedSelection={setCheckedSelection}
          checkedSelection={checkedSelection}
          setCheckedSelectionNames={setCheckedSelectionNames}
          checkedSelectionNames={checkedSelectionNames}
          setFleetReports={setFleetReports}
          startDate={fleetStartDate ? fleetStartDate : new Date()}
          endDate={fleetEndDate ? fleetEndDate : new Date()}
        />
      )}
      {vehiclesForFleetDialogOpen && (
        <VehicleForFleetDialog
          selectableIds={vehicleIds.map((vehicle) => vehicle.id)}
          setDialogOpen={setVehiclesForFleetDialogOpen}
          setCheckedSelection={setVehicleCheckedSelection}
          checkedSelection={vehicleCheckedSelection}
        />
      )}
      {filteredFleetReports.length !== 0 && (
        <div className="target-and-fuel-graphs">
          <p className="reports-second-paragraph">Fleet Vehicles Compared To Target </p>
          <div className="report-date">
            <p className="report-date-text">Charts Analysis Dates: </p>
            <DatePicker
              className="date-picker"
              selected={fleetChartStartDate}
              onChange={handleFleetChartDateChange}
              startDate={fleetChartStartDate}
              endDate={fleetChartEndDate}
              selectsRange
              disabled={true}
              dateFormat={'dd/MM/yyyy'}
            />
          </div>
          <Button
            className="select-vehicles-data-button"
            label={'Analyzed Vehicles (' + vehicleCheckedSelection.length + ')'}
            icon={{
              iconName: 'add',
              title: 'Select fleets',
            }}
            onClick={handleVehicleDialogClick}
          />

          <div className="area-and-pie-chart">
            <FleetAreaChart
              target={co2ReductionTarget}
              vehicleCheckedSelection={vehicleCheckedSelection}
              selectedComparatorName={selectedComparatorName}
              selectedGhg={selectedGhg}
            />
            <FleetPieChart vehicleCheckedSelection={vehicleCheckedSelection} />
          </div>
        </div>
      )}

      <div className="report-date">
        <p className="report-date-text">Table Report Dates: </p>
        <DatePicker
          className="date-picker"
          selected={fleetStartDate}
          onChange={handleFleetDateChange}
          startDate={fleetStartDate}
          endDate={fleetEndDate}
          selectsRange
          disabled={true}
          dateFormat={'dd/MM/yyyy'}
        />
      </div>

      <div className="fleet-results">
        {filteredFleetReports.length !== 0 && <Co2FleetReportTable reports={filteredFleetReports} />}
        {filteredFleetReports.length === 0 && fleetReports.length !== 0 && <Co2FleetReportTable reports={fleetReports} />}
        {fleetReports.length === 0 && <p className="no-reports-paragraph">No Fleet Reports yet...</p>}
        <div id="fleet-page-indicator">
          <PageIndicator numbered defaultSelected={1} pages={numberOfPages} />
        </div>
      </div>
    </div>
  )
}

export default FleetsReport
