import React, { useEffect, useState } from 'react'
import DatePicker from 'react-datepicker'
import CustomerReportData from '../../models/CustomerReportData'
import Co2Report from '../../atoms/Co2Report'
import { customerTableData } from '../../__mocks__/data/MockReport'
import EmissionsAndConsumtion from '../../molecules/EmissionsAndConsumption/EmissionsAndConsumtion'
import 'react-datepicker/dist/react-datepicker.css'
import './MainSections.scss'
import Co2Reduction from '../../molecules/Co2Reduction/Co2Reduction'
import { IReportAnalysisProps } from '../../propsInterfaces/IReportProps'
import { mockReportsBasedOnGhg } from '../../__mocks__/generators/ReportGenerator'

const CustomerReport: React.FunctionComponent<IReportAnalysisProps> = ({ co2ReductionTarget, percentage, selectedGhg }) => {
  const currentDate = new Date()
  const firstDate = new Date(2023, 11, 22)
  const [customerStartDate, setCustomerStartDate] = useState<Date | null>(firstDate)
  const [customerEndDate, setCustomerEndDate] = useState<Date | null>(currentDate)
  const [customerReports, setCustomerReports] = useState<CustomerReportData[]>(customerTableData)

  const handleCustomerDateChange = (range: [Date | null, Date | null]) => {
    const [startDate, endDate] = range
    setCustomerStartDate(startDate)
    setCustomerEndDate(endDate)
  }

  useEffect(() => {
    setCustomerReports(mockReportsBasedOnGhg(customerTableData, selectedGhg) as CustomerReportData[])
  }, [selectedGhg])

  return (
    <div className="main-section">
      <p className="reports-paragraph">Your Company Report </p>
      <div className="main-graphs">
        <EmissionsAndConsumtion reportType="Customer" />
        <Co2Reduction reportType="Customer" co2ReductionTarget={co2ReductionTarget} percentage={percentage} selectedGhg={selectedGhg} />
      </div>
      <div className="report-date">
        <p className="report-date-text">Entry Report Dates:</p>
        <DatePicker
          className="date-picker"
          selected={customerStartDate}
          onChange={handleCustomerDateChange}
          startDate={customerStartDate}
          endDate={customerEndDate}
          selectsRange
          disabled={true}
          dateFormat={'dd/MM/yyyy'}
        />
      </div>
      <div className="customer-results">
        {customerReports.length !== 0 && <Co2Report report={customerReports[0]} />}
        {customerReports.length === 0 && <p className="no-reports-paragraph">No Customer Reports yet...</p>}
      </div>
    </div>
  )
}

export default CustomerReport
