import React, { useState } from 'react'
import { ISelectionDialogProps } from '../../propsInterfaces/IDialogProps'
import { Dialog, SearchForm, Notification } from '@bosch/react-frok'
import './SelectionDialog.scss'
import SelectionTable from '../../atoms/tables/SelectionTable'

const SelectionDialog: React.FunctionComponent<ISelectionDialogProps> = ({
  selectableNames,
  selectableIds,
  setDialogOpen,
  setCheckedSelection,
  checkedSelection,
  setCheckedSelectionNames,
  checkedSelectionNames,
}) => {
  const maxSelection = 4
  const [maxSelectionExceeded, setMaxSelectionExceeded] = useState<boolean>(false)
  const [searchString, setSearchString] = useState<string>('')
  const [searchFilter, setSearchFilter] = useState<string>('')

  const handleSearchString = (event: any) => {
    setSearchString(event.target.value)
  }

  const handleFilesSearch = () => {
    setSearchFilter(searchString)
  }

  const handleResetSearch = () => {
    setSearchFilter('')
  }

  const getFilteredBySearch = (search: string) => {
    if (selectableNames) {
      return selectableNames.filter((selectableName) => selectableName.toLowerCase().includes(search.toLowerCase()))
    }
    return selectableIds.filter((selectableId) => selectableId.includes(search))
  }

  return (
    <div className="dialog-and-overlay">
      <div className="overlay"></div>
      <Dialog
        className="select-dialog-for-datasets"
        onCancel={() => {
          setDialogOpen(false)
        }}
        onClose={() => {
          setDialogOpen(false)
        }}
        onConfirm={() => {
          setDialogOpen(false)
        }}
        title="Select data to analyze"
      >
        <SearchForm
          className="search-selectables"
          suggestions={null}
          onChange={handleSearchString}
          onReset={handleResetSearch}
          onSubmit={handleFilesSearch}
          searchField={{
            id: 'searchFieldId',
          }}
        />
        <SelectionTable
          selectableNames={searchFilter ? getFilteredBySearch(searchFilter) : selectableNames}
          selectableIds={searchFilter ? getFilteredBySearch(searchFilter) : selectableIds}
          setCheckedSelection={setCheckedSelection}
          checkedSelection={checkedSelection}
          setCheckedSelectionNames={setCheckedSelectionNames}
          checkedSelectionNames={checkedSelectionNames}
          maxSelection={maxSelection}
          setMaxSelectionExceeded={setMaxSelectionExceeded}
        />
        {maxSelectionExceeded && <Notification type="warning">Maximum four selections allowed</Notification>}
      </Dialog>
    </div>
  )
}

export default SelectionDialog
