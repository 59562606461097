import React, { useState } from 'react'
import { ChartData, ChartOptions } from 'chart.js'
import './FleetAreaAndPieChart.scss'
import 'chart.js/auto'
import { IFleetGraphProps } from '../../../propsInterfaces/IGraphsProps'
import { Line } from 'react-chartjs-2'
import { formatWithMaxFourFractionDigits } from '../../../services/formatNumber'
import { mockMonths } from '../../../__mocks__/data/MockAreaChartData'
import { Notification } from '@bosch/react-frok'
import {
  mockFossilSimulatedByVehicles,
  mockLimitedFuelByVehicles,
  mockRealFuelCo2ByVehicles,
} from '../../../__mocks__/generators/FleetChartGenerator'

const FleetAreaChart: React.FunctionComponent<IFleetGraphProps> = ({
  target,
  vehicleCheckedSelection,
  selectedComparatorName,
  selectedGhg,
}) => {
  const xAxisData = mockMonths
  const fossilSimulatedData = mockFossilSimulatedByVehicles(vehicleCheckedSelection, selectedGhg)
  const realFuelCo2Data = mockRealFuelCo2ByVehicles(vehicleCheckedSelection, selectedGhg)
  const limitedFuelData = mockLimitedFuelByVehicles(vehicleCheckedSelection, selectedGhg)

  const lastTotalFossilData = fossilSimulatedData.at(-1) ?? 0
  const targetFromPercentage = lastTotalFossilData * (1 - target / 100)
  const isSmallScreen = window.innerWidth < 500

  const targetString = 'Target: ' + formatWithMaxFourFractionDigits(targetFromPercentage) + ' tons CO₂eq'
  const [isTargetDrawingVisible, setIsTargetDrawingVisible] = useState<boolean>(true)

  const chartOptions: ChartOptions<'line'> = {
    responsive: true,
    maintainAspectRatio: true,
    aspectRatio: isSmallScreen ? 1.4 : 2,
    scales: {
      x: {
        title: {
          display: true,
          text: 'Date',
        },
      },
      y: {
        title: {
          display: true,
          text: 'tons CO₂eq',
        },
      },
    },
    plugins: {
      datalabels: {
        display: false,
      },
      annotation: {
        annotations: {
          line1: {
            type: 'line',
            yMin: targetFromPercentage,
            yMax: targetFromPercentage,
            borderColor: 'rgba(24, 131, 126)',
            borderWidth: 2,
            borderDash: [6, 6],
            label: {
              content: 'Target CO₂: ' + targetFromPercentage,
              position: 'end',
              backgroundColor: 'rgba(24, 131, 126)',
            },
            display: isTargetDrawingVisible,
          },
        },
      },
      tooltip: {
        backgroundColor: '#FFFFFF',
        bodyColor: '#000000',
        titleColor: '#000000',
        borderColor: '#C1C7CC',
        borderWidth: 1,
        callbacks: {
          label: function (tooltip) {
            const value = tooltip.raw as number
            return tooltip.dataset.label + ': ' + formatWithMaxFourFractionDigits(value) + ' tons CO₂eq'
          },
        },
      },

      legend: {
        onClick: (e, legendItem, legend) => {
          const chart = legend.chart
          if (legendItem.text === targetString) {
            setIsTargetDrawingVisible(!isTargetDrawingVisible)
          }
          const datasetIndex = legendItem.datasetIndex
          const meta = chart.getDatasetMeta(datasetIndex || 0)
          meta.hidden = typeof meta.hidden === 'boolean' ? !meta.hidden : true
          chart.update()
        },
        display: true,
        position: 'bottom',
        align: 'start',
        labels: {
          boxHeight: 0,
          boxWidth: 20,
          font: {
            size: 12,
          },
        },
      },
    },
    elements: {
      line: {
        borderWidth: 2,
      },
      point: {
        radius: 2,
        hoverRadius: 5,
      },
    },
    layout: {
      padding: 20,
    },
  }

  const chartData: ChartData<'line'> = {
    labels: xAxisData,
    datasets: [
      {
        label: 'Fossil Simulated Data',
        data: fossilSimulatedData,
        fill: true,
        backgroundColor: 'rgba(75,192,192,0.2)',
        borderColor: 'rgba(75,192,192,1)',
      },
      {
        label: 'Real Fuel CO₂ Data',
        data: realFuelCo2Data,
        fill: true,
        backgroundColor: 'rgba(153,102,255,0.2)',
        borderColor: 'rgba(153,102,255,1)',
      },
      {
        label: 'Limited Fuel Data',
        data: limitedFuelData,
        fill: true,
        backgroundColor: 'rgba(153,102,255,0.1)',
        borderColor: 'rgba(153,102,255,0.5)',
        borderDash: [3, 3],
      },
      {
        label: targetString,
        data: [],
        fill: false,
        backgroundColor: 'rgba(24, 131, 126)',
        borderColor: 'rgba(24, 131, 126)',
        borderDash: [6, 5],
        pointRadius: 0,
        borderCapStyle: 'round',
        borderWidth: 2,
      },
    ],
  }

  return (
    <div className="fleet-line-graph">
      <div className="fleet-vehicles-dashboard">
        <div className="title-section">
          <p className="title">CO₂ Calculation based on Recognized Fuel Amount (tons CO₂eq)</p>
        </div>
      </div>
      <Line data={chartData} options={chartOptions} />
      <Notification icon="alert-info" className="chart-info">
        The Fueling Amount includes only used fuel, not the latest filling event, and is compared with the chosen comparator:{' '}
        <b>{selectedComparatorName}</b>
      </Notification>
    </div>
  )
}

export default FleetAreaChart
