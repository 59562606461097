import React from 'react'
import { Box } from '@bosch/react-frok'
import './Disclaimer.scss'

interface IDisclaimerProps {
  disclaimerOpen: boolean
}

const Disclaimer: React.FunctionComponent<IDisclaimerProps> = ({ disclaimerOpen }) => {
  const userClass = disclaimerOpen ? 'info-open' : 'info-close'

  return (
    <div className="side-disclaimer">
      <Box shadow className={userClass}>
        <div className="title">Disclaimer: Last Data Input Information</div>
        <div className="content">The last data input on this website was on 09/05/2024</div>
      </Box>
    </div>
  )
}

export default Disclaimer
