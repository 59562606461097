import { Configuration, PopupRequest } from "@azure/msal-browser";
import { environmentConstants } from "./mainConfig";

const msalConfig: Configuration = {
  auth: {
    clientId: environmentConstants.AAD_CLIENT_ID,
    authority:
      "https://login.microsoftonline.com/" +
      environmentConstants.AAD_TENANT_ID,
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false,
  },
};

export default msalConfig;

export const loginRequest: PopupRequest = {
    scopes: ['User.Read', 'profile']
};