import React from 'react'
import { IReductionsGraphProps } from '../../propsInterfaces/IGraphsProps'
import { ChartData, ChartOptions } from 'chart.js'
import { Bar } from 'react-chartjs-2'
import './ReportsGraph.scss'
import 'chart.js/auto'
import { filterLastXDays } from '../../services/graphUtil'
import { formatWithMinZeroFractionDigits } from '../../services/formatNumber'
import { getUnit } from '../../services/reportsUtil'

const Co2ReductionGraph: React.FunctionComponent<IReductionsGraphProps> = ({ datasets, reportType, selectedLastXDays }) => {
  const colors = ['#9E2896', '#18837E', '#007BC0', '#5FB583']

  const unitDivision = reportType === 'Vehicle' ? 1000 : 1000000

  const filteredDaysDatasets = datasets.map((dataset) => ({
    ...dataset,
    data: filterLastXDays(dataset.data, selectedLastXDays),
  }))

  const chartOptions: ChartOptions<'bar'> = {
    plugins: {
      datalabels: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: function (tooltip) {
            const value = tooltip.raw as number
            const name = tooltip.dataset.label
            const unit = ' CO₂ Reductions (' + getUnit(reportType) + ')'
            return name + ': ' + formatWithMinZeroFractionDigits(value) + unit
          },
        },
        backgroundColor: '#FFFFFF',
        bodyColor: '#000000',
        titleColor: '#000000',
        borderColor: '#C1C7CC',
        borderWidth: 1,
      },
      legend: {
        display: false,
      },
    },
    scales: {
      y: {
        title: {
          display: true,
          text: getUnit(reportType),
        },
        ticks: {
          stepSize: 5,
        },
      },
      x: {
        title: {
          display: true,
          text: 'Days',
        },
      },
    },
    layout: {
      padding: 10,
    },
  }

  const chartData: ChartData<'bar'> = {
    labels: filteredDaysDatasets[0]?.data.map((d) => d.x) || [],
    datasets: filteredDaysDatasets.map((dataset, index) => ({
      label: dataset.label,
      data: dataset.data.map((d) => (d.y !== 0 ? Math.round(d.y) / unitDivision : null)),
      barPercentage: 0.8,
      maxBarThickness: 30 - datasets.length,
      borderColor: colors[index % colors.length],
      backgroundColor: colors[index % colors.length],
      tension: 0.1,
    })),
  }

  return (
    <div className="reduction-reports-graph">
      <div className="dashboard">
        <p className="reduction-title">Current CO₂ Reduction</p>
      </div>
      <Bar data={chartData} options={chartOptions} />
    </div>
  )
}

export default Co2ReductionGraph
