import React from 'react'
import { Table, TableHead, TableRow, TableCell, TableBody } from '@bosch/react-frok'
import { IVehicleTableProps } from '../../../propsInterfaces/ITableProps'
import Co2VehicleReportTableCell from '../cells/Co2VehicleReportTableCell'
import './ReportTable.scss'

const Co2VehicleReportTable: React.FunctionComponent<IVehicleTableProps> = ({ reports }) => {
  return (
    <div className="vehicle-reports-table">
      <Table>
        <TableHead>
          <TableRow>
            <TableCell header secondary>
              Vehicle
            </TableCell>
            <TableCell header secondary>
              CO₂ Emissions (kg CO₂eq)
            </TableCell>
            <TableCell header secondary>
              Number of Refuel Events
            </TableCell>
            <TableCell header secondary>
              Refuel Amount (liters)
            </TableCell>
            <TableCell header secondary>
              CO₂ Savings vs. Fossil Fuels (kg CO₂eq)
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {reports.map((report) => (
            <TableRow key={report.vehicleName}>
              <Co2VehicleReportTableCell report={report} />
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  )
}

export default Co2VehicleReportTable
