interface GlossaryDescription {
  text?: string
  formula?: string
}

interface GlossaryInfo {
  name: string
  description?: GlossaryDescription
  unit?: string
  norms?: string[]
}

export const glossary: GlossaryInfo[] = [
  { name: 'ghg', description: { text: 'equivalent Green House Gases emitted per produced energy unit' }, unit: 'gCO2eq/MJ' },
  { name: 'lhv', description: { text: 'lower heating value' }, unit: 'MJ/l' },
  { name: 'Density', unit: 'g/cm3' },
  {
    name: 'Transportation CO2 Footprint At Creation',
    description: {
      formula: 'transportation CO2 Footprint [gCO2eq] = distance [km] *avgFuelConsumption [l/km] *  2680  [gCO2/l]',
      text: `fuelType → will declare CO2 footprint in terms of gCO2 per liter consumed (diesel → 2680  gCO2/l)
            avgFuelConsumption → will declare the average consumption of the transportation truck
            distance → will declare how many kilometers have been run by the truck`,
    },
    unit: 'gCO2eq',
  },
  {
    name: 'Savings and Comparator',
    description: {
      formula: `Greenhouse gas emission savings:
        Savings = (EF - E)/EF`,
      text: `E = total emissions from the use of renewable liquid and gaseous transport fuel of non-biological origin or recycled carbon fuel
            EF = total emissions from the fossil fuel comparator
            For fossile to lcf comparison we use official value of 94 gCO2eq/MJ`,
    },
  },
  { name: 'Fuel Norms', description: { text: 'Intended norms to be compliant for the fuel batch' }, norms: ['EN 590', 'ASTM D975'] },
]
