import React, { useState } from 'react'
import { IVehiclesForFleetDialogProps } from '../../propsInterfaces/IDialogProps'
import { Dialog, SearchForm } from '@bosch/react-frok'
import './VehicleForFleetDialog.scss'
import VehiclesForFleetTable from '../../atoms/tables/vehiclesForFleet/VehiclesForFleetTable'

const VehicleForFleetDialog: React.FunctionComponent<IVehiclesForFleetDialogProps> = ({
  selectableIds,
  setDialogOpen,
  setCheckedSelection,
  checkedSelection,
}) => {
  const [searchString, setSearchString] = useState<string>('')
  const [searchFilter, setSearchFilter] = useState<string>('')

  const handleSearchString = (event: any) => {
    setSearchString(event.target.value)
  }

  const handleFilesSearch = () => {
    setSearchFilter(searchString)
  }

  const handleResetSearch = () => {
    setSearchFilter('')
  }

  const getFilteredBySearch = (search: string) => {
    return selectableIds.filter((selectableId) => selectableId.includes(search))
  }

  return (
    <div className="dialog-and-overlay">
      <div className="overlay"></div>
      <Dialog
        className="select-dialog-for-datasets"
        onCancel={() => {
          setDialogOpen(false)
        }}
        onClose={() => {
          setDialogOpen(false)
        }}
        onConfirm={() => {
          setDialogOpen(false)
        }}
        title="Select data to analyze"
      >
        <SearchForm
          className="search-selectables"
          suggestions={null}
          onChange={handleSearchString}
          onReset={handleResetSearch}
          onSubmit={handleFilesSearch}
          searchField={{
            id: 'vehicleSearchFieldId',
          }}
        />
        <VehiclesForFleetTable
          selectableIds={searchFilter ? getFilteredBySearch(searchFilter) : selectableIds}
          setCheckedSelection={setCheckedSelection}
          checkedSelection={checkedSelection}
        />
      </Dialog>
    </div>
  )
}

export default VehicleForFleetDialog
