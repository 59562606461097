import React, { useEffect, useState } from 'react'
import { MinimalHeader, SideNavigation } from '@bosch/react-frok'
import { INavigationProps } from '../propsInterfaces/INavigationProps'
import { fetchUserAttributes, signOut } from 'aws-amplify/auth'
import Disclaimer from '../molecules/Disclaimer/Disclaimer'
import Glossary from '../molecules/Glossary/Glossary'

const Navigation: React.FunctionComponent<INavigationProps> = ({ pageTitle, authInstance, authMethod }) => {
  const [externalEmail, setExternalEmail] = useState<string>('No active account')
  const [disclaimerOpen, setDisclaimerOpen] = useState(false)
  const [glossaryOpen, setGlossaryOpen] = useState(false)

  const handleInfoClick = () => {
    if (glossaryOpen) {
      setGlossaryOpen(false)
    }
    setDisclaimerOpen(!disclaimerOpen)
  }

  const handleGlossaryClick = () => {
    if (disclaimerOpen) {
      setDisclaimerOpen(false)
    }
    setGlossaryOpen(!glossaryOpen)
  }

  const checkCognitoCredentials = async () => {
    if (authMethod === 'cognito') {
      try {
        const account = await fetchUserAttributes()
        setExternalEmail(account.email || 'No active account')
      } catch (error) {
        return null
      }
    }
  }

  useEffect(() => {
    checkCognitoCredentials()
  }, [])

  const logout = () => {
    if (authMethod === 'cognito') {
      signOut()
      window.location.reload()
    } else if (authMethod === 'msal') {
      authInstance.logoutRedirect()
    }
  }

  const getActiveAccount = () => {
    const account = authInstance.getActiveAccount()
    return account ? account.name : externalEmail
  }

  const actions = [
    {
      icon: 'user',
      label: getActiveAccount(),
      showLabel: true,
      value: 'user',
    },
    {
      icon: 'question-frame',
      showLabel: true,
      value: 'about',
      onClick: handleGlossaryClick,
    },
    {
      icon: 'alert-info',
      showLabel: true,
      value: 'info',
      onClick: handleInfoClick,
    },
  ]

  const menuItems = [
    {
      icon: 'logout',
      label: 'Logout',
      link: {
        action: 'submit',
        as: 'button',
        onClick: logout,
      },
      value: 'log out',
    },
  ]

  return (
    <div className="main-header">
      <MinimalHeader
        actions={actions}
        logo={{
          'aria-label': 'My Logo',
          href: 'https://bosch.com',
          target: '_blank',
          title: 'My Logo',
        }}
        sideNavigation={
          <SideNavigation
            body={{
              menuItems: menuItems,
            }}
            header={{
              label: 'Menu',
            }}
          />
        }
      >
        {pageTitle}
        <Disclaimer disclaimerOpen={disclaimerOpen} />
        <Glossary glossaryOpen={glossaryOpen} />
      </MinimalHeader>
    </div>
  )
}

export default Navigation
