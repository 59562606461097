import { co2AllReductionsVehicleDatasets, co2DataTotalMay, fuelDataTotalMay } from '../__mocks__/data/MockGraphs'
import { vehicleIds } from '../__mocks__/data/MockSlectables'
import { DataByDate, GraphType } from '../propsInterfaces/IGraphsProps'

export const extractMonthValue = (input: string): number => {
  const words = input.split(' ')
  const number = parseInt(words[1], 10)
  if (!isNaN(number) && number >= 1 && number <= 13) return number

  return 13
}

export const extractMonthString = (): string[] => {
  const array = Array.from({ length: 13 }, (_, i) => {
    const monthCount = 13 - i
    return monthCount === 1 ? `Last 1 month` : `Last ${monthCount} months`
  })
  return array
}

export const extractDayString = () => {
  const array = Array.from({ length: 7 }, (_, i) => {
    const dayCount = 7 - i
    return dayCount === 1 ? { value: dayCount, name: `Last 1 day` } : { value: dayCount, name: `Last ${dayCount} days` }
  })
  return array
}

export const getDatasetTotals = (
  datasets: {
    label: string
    data: DataByDate[]
  }[],
): number[] => {
  return datasets.map((dataset) => Math.round(dataset.data.reduce((sum, item) => sum + item.y, 0)))
}

export const parseMonthString = (monthStr: string): Date => {
  const [month, year] = monthStr.split(' ')
  const monthIndex = new Date(`${month} 1, ${year}`).getMonth()
  return new Date(Number(year), monthIndex)
}

export const parseXToDate = (x: string | number): Date | null => {
  if (typeof x === 'string') {
    return parseMonthString(x)
  } else if (typeof x === 'number') {
    return new Date(x)
  }
  return null
}

const isVehicleId = (datasetLabel: string) => {
  return vehicleIds.some((vehicle) => vehicle.id === datasetLabel)
}

export const filterLastXMonths = (
  data: { x: string | number; y: number }[],
  referenceMonth: string,
  months: number,
  graphType: GraphType,
  datasetLabel: string,
): { x: string | number; y: number }[] => {
  if (months === 1 && !isVehicleId(datasetLabel)) {
    if (graphType === 'CO₂ Emissions') {
      return co2DataTotalMay
    } else if (graphType === 'Refuel Amount') {
      return fuelDataTotalMay
    }
  }
  const refDate = parseMonthString(referenceMonth)
  const filteredData = data.filter((entry) => {
    const entryDate = parseXToDate(entry.x)

    if (!entryDate) {
      return false
    }

    const monthDiff = (refDate.getFullYear() - entryDate.getFullYear()) * 12 + (refDate.getMonth() - entryDate.getMonth())

    return monthDiff >= 0 && monthDiff < months
  })
  return filteredData
}

export const getSelectedData = (selectedData: string[] | undefined) => {
  if (selectedData) {
    const result = co2AllReductionsVehicleDatasets.filter((vehicle) =>
      selectedData.some((substring) => vehicle.label.toLowerCase().includes(substring.toLowerCase())),
    )
    return result
  }
  return co2AllReductionsVehicleDatasets
}

function parseDate(dateString: string) {
  const currentYear = new Date().getFullYear()
  return new Date(Date.parse(`${dateString}, ${currentYear}`))
}

export function filterLastXDays(entries: { x: any; y: number }[], lastXDays: number) {
  const today = new Date(2024, 4, 9)
  const filtered = entries.filter((entry) => {
    const entryDate = parseDate(entry.x)
    const diffInTime = today.getTime() - entryDate.getTime()
    const diffInDays = diffInTime / (1000 * 3600 * 24)
    return diffInDays < lastXDays
  })
  return filtered
}
